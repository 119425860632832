import { darkThemes } from "../../constants/themes";

export default function CAPatientTable({
  currentQuestion,
  textSize,
  getHighlightedText,
  theme,
}) {
  const darkMode = darkThemes.includes(theme);
  const headerBackground = darkMode ? "bg-[#2A2C31]" : "bg-[#d6e0f5]";
  const textColor = darkMode ? "text-contrastText" : "text-primaryText"; //canada mode not changed by themes
  return (
    <table
      className={`min-w-full table-auto border border-primaryDark ${textColor}`}
    >
      <tbody>
        <>
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
              data-id="patientHeader"
            >
              <span data-id="container-break">
                {getHighlightedText("Patient", "patientHeader")}
              </span>
            </td>
          </tr>
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="PatientAge"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  `${currentQuestion.GetQuestion.patient_gender}, ${currentQuestion.GetQuestion.patient_age} year old`,
                  "PatientAge"
                )}
              </span>
            </td>
          </tr>
        </>
        {currentQuestion.GetQuestion.chief_complaint && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
                data-id="chief_complaint_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Chief Complaint",
                    "chief_complaint_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="chief_complaint_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    `"${currentQuestion.GetQuestion.chief_complaint}"`,
                    "chief_complaint_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
        {currentQuestion.GetQuestion.dental_history && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
                data-id="dental_history_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Background and/or Patient History",
                    "dental_history_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="dental_history_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    currentQuestion.GetQuestion.dental_history,
                    "dental_history_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
        {currentQuestion.GetQuestion.medical_history && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
                data-id="medical_history_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Medical History:",
                    "medical_history_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="medical_history_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    currentQuestion.GetQuestion.medical_history,
                    "medical_history_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
        {currentQuestion.GetQuestion.clinical_findings && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
                data-id="clinical_findings_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Current Findings",
                    "clinical_findings_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="clinical_findings_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    currentQuestion.GetQuestion.clinical_findings,
                    "clinical_findings_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
        {currentQuestion.GetQuestion.radiographic_findings && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full ${headerBackground} ${textSize.left}`}
                data-id="radiographic_findings_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Radiographic Findings",
                    "radiographic_findings_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="radiographic_findings_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    currentQuestion.GetQuestion.radiographic_findings,
                    "radiographic_findings_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
        {currentQuestion.GetQuestion.histological_findings && (
          <>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark w-full bg-[#d6e0f5] ${textSize.left}`}
                data-id="histological_findings_label"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Histological Findings",
                    "histological_findings_label"
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td
                className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
                data-id="histological_findings_text"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    currentQuestion.GetQuestion.histological_findings,
                    "histological_findings_text"
                  )}
                </span>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}

import { apiSlice } from "../apiSlice";

const secondaryUrl = "getuserfeedback.webapi";
const feedbackExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitFeedbackByQuestionId: builder.mutation({
      query: ({ question_id, feedback }) => ({
        url: `${secondaryUrl}/`,
        method: "POST",
        body: { question_id: question_id, feedback: feedback }, // Pass the body here for POST
      }),
    }),
  }),
});

export const { useSubmitFeedbackByQuestionIdMutation } = feedbackExtendedSlice;

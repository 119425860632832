import {
  testgrayBackground,
  testgrayText,
  testgreenBackground,
  testgreenText,
  testorangeBackground,
  testorangeText,
  testRedBackground,
  testRedText,
} from "../constants/test";

export const formatTimeLeft = (milliseconds: number) => {
  const totalSeconds = Math.max(0, Math.floor(milliseconds / 1000));
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const formatDateTime = (timezone, dateTime) => {
  if (timezone == undefined) {
    return "Timezone undefined";
  }

  const dateFormatOptions = {
    timeZone: timezone,
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  // @ts-expect-error. This works, no clue why it says there is no override
  const formatter = new Intl.DateTimeFormat("en-US", dateFormatOptions);

  return formatter.format(dateTime);
};

export const getReviewColors = (Color) => {
  let colorString = "bg-purple-200";
  let textString = "";
  switch (Color) {
    case "red":
      colorString = testRedBackground;
      textString = testRedText;
      break;
    case "gray":
      colorString = testgrayBackground;
      textString = testgrayText;
      break;
    case "green":
      colorString = testgreenBackground;
      textString = testgreenText;
      break;
    case "orange":
      colorString = testorangeBackground;
      textString = testorangeText;
      break;
  }

  return { colorString, textString };
};

export function daysUntil(targetDate) {
  const today = new Date(); // Get today's date
  const target = new Date(targetDate); // Parse the target date

  // Calculate the difference in milliseconds
  // @ts-expect-error. Dates work with subtraction
  const difference = target - today;

  // Convert the difference to days (1 day = 24 * 60 * 60 * 1000 ms)
  return Math.ceil(difference / (1000 * 60 * 60 * 24));
}


export const tempTimezone = "Asia/Tehran";

export function limitDigits(number, digits=3) {
  const significantDigits = number
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/^0+/, "").length;

  if (significantDigits >= digits) {
    return Number(number.toPrecision(digits));
  }

  return number; // Return the original number if it doesn't have enough digits
}

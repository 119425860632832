export default function PeridontalChart({
  extraData,
  textSize,
  getHighlightedText,
  altMode,
}) {
  const teethArray = Object.keys(extraData);
  return (
    <div className="flex">
      <table>
        <tbody>
          <tr>
            <td className="vestibularField vestibularTitle">{`${
              altMode ? "LINGUAL" : "VESTIBULAR"
            }`}</td>
          </tr>
          <tr className="vestibularDataRow">
            <td
              className={`border border-primaryDark ${textSize.left} bg-primaryLight text-primaryLight toothRow toothZero`}
              data-id="tooth_zero"
            >
              TEST
            </td>
          </tr>
        </tbody>
      </table>
      <table className="border border-primaryDark">
        <tbody>
          {/*Furcation*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark  bg-primaryLight ${textSize.left} vestibularCell vestibularTitle`}
              data-id="furcation_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Furcation", "furcation_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([index, value]) => {
              const dataId = `furcation_${index}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} vestibularCell`}
                  data-id={dataId}
                >
                  <div className="flex justify-between space-x-5">
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.furcation[0]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.furcation[1]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.furcation[2]}`,
                        dataId
                      )}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*CAL*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark  bg-primaryLight ${textSize.left} vestibularCell vestibularTitle`}
              data-id="cal_label"
            >
              <span data-id="container-break">
                {getHighlightedText("CAL", "cal_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([index, value]) => {
              const dataId = `cal_${index}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} vestibularCell`}
                  data-id={dataId}
                >
                  <div className="flex justify-between space-x-5">
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.cal[0]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.cal[1]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.cal[2]}`,
                        dataId
                      )}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Recession*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark  bg-primaryLight ${textSize.left} vestibularCell vestibularTitle`}
              data-id="recession_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Recession", "recession_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([index, value]) => {
              const dataId = `recession_${index}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} vestibularCell`}
                  data-id={dataId}
                >
                  <div className="flex justify-between space-x-5">
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.recession[0]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.recession[1]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.recession[2]}`,
                        dataId
                      )}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*BOP*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark  bg-primaryLight ${textSize.left} vestibularCell vestibularTitle`}
              data-id="bop_label"
            >
              <span data-id="container-break">
                {getHighlightedText("BOP", "bop_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([index, value]) => {
              const dataId = `bop_${index}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} vestibularCell`}
                  data-id={dataId}
                >
                  <div className="flex justify-between space-x-5">
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.bop[0]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.bop[1]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.bop[2]}`,
                        dataId
                      )}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Probing*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark  bg-primaryLight ${textSize.left} vestibularCell vestibularTitle`}
              data-id="probing_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Probing", "probing_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([index, value]) => {
              const dataId = `probing_${index}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} vestibularCell`}
                  data-id={dataId}
                >
                  <div className="flex justify-between space-x-5">
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.probing[0]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.probing[1]}`,
                        dataId
                      )}
                    </div>
                    <div data-id="container-break">
                      {getHighlightedText(
                        `${extraData[value]?.probing[2]}`,
                        dataId
                      )}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Tooth*/}
          <tr className="vestibularDataRow">
            <td
              className={` border border-primaryDark bg-primaryLight ${textSize.left} bg-primaryLight toothRow`}
              data-id="tooth_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Tooth", "tooth_label")}
              </span>
            </td>
            {Object.entries(teethArray).map(([key, value]) => {
              const dataId = `tooth_${key}`;
              return (
                <td
                  className={` border border-primaryDark ${textSize.left} bg-primaryLight toothRow`}
                  data-id={dataId}
                >
                  <span data-id="container-break">
                    {getHighlightedText(`${value}`, dataId)}
                  </span>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

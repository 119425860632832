import { Provider } from 'react-redux';
import './App.css';
import Routers from './routes/Routers';
import { store } from './store';
import ToastWrapper from './components/Wrappers/toastWrapper';

function App() {
	return (
    <div className="App">
      <Provider store={store}>
        <ToastWrapper>
          <Routers />
        </ToastWrapper>
      </Provider>
    </div>
  );
}

export default App;

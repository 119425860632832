import {
  ChartBar,
  ChevronLeft,
  ChevronRight,
  History,
  Menu,
  PlusCircle,
  Settings,
} from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSidebarHidden, getUserData } from "../../store/login.slice";
import { formatDateTime } from "../../helpers/formatters";

const LayoutWrapper = ({ children }) => {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const hidden = useSelector(getSidebarHidden);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const userData = useSelector(getUserData);
  const expiration = userData.license_expiration;
  const formattedDate = formatDateTime(
    userData.time_zone.value,
    new Date(expiration)
  );

  return (
    <div
      className={`grid min-h-screen w-full ${
        hidden
          ? ""
          : sidebarOpen
          ? "lg:grid-cols-[280px_1fr]"
          : "lg:grid-cols-[160px_1fr]"
      } bg-secondaryBackground`}
    >
      {/* Sidebar */}
      {!hidden && (
        <div
          className={`bg-primaryDark hidden border-r lg:block transition-all duration-300 ease-in-out ${
            sidebarOpen ? "w-[280px]" : "w-[160px]"
          }`}
        >
          <div className="sticky top-0">
            <div className="flex h-[100vh] flex-col">
              {/* Sidebar Header */}
              <div className="flex h-14 items-center border-b border-white/10 px-4 lg:h-[250px] lg:px-6">
                {/*<img
                  src={"src/assets/dental.png"}
                  className="h-28 w-28"
                  onClick={toggleSidebar}
                />*/}
                <span className="text-contrastText font-bold text-lg ">
                  OSCE Dental
                </span>
                <button
                  className="ml-auto h-8 w-8 text-white hover:bg-white/10"
                  onClick={toggleSidebar}
                >
                  {sidebarOpen ? (
                    <ChevronLeft className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                  <span className="sr-only">Toggle sidebar</span>
                </button>
              </div>

              {/* Sidebar Navigation */}
              <div className="flex-1 overflow-y-auto">
                <nav className="grid items-start px-2 text-lg font-medium">
                  <button
                    onClick={() => navigate("/CreateTest")}
                    className={`${
                      location.pathname.includes("/createtest")
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                    } w-full flex items-center gap-3 rounded-lg px-3 py-2 text-left transition-all`}
                    title="Create Test"
                  >
                    <PlusCircle className="h-4 w-4" />
                    <span className={`${sidebarOpen ? "" : "text-xs"}`}>
                      Create Test
                    </span>
                  </button>
                  <button
                    onClick={() => navigate("/completetests")}
                    className={`${
                      location.pathname.includes("/completetests")
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                    } w-full flex items-center gap-3 rounded-lg px-3 py-2 text-left transition-all`}
                    title="Complete Tests"
                  >
                    <History className="h-4 w-4" />
                    <span className={`${sidebarOpen ? "" : "text-xs"}`}>
                      Complete Tests
                    </span>
                  </button>
                  <button
                    onClick={() => navigate("/incompletetests")}
                    className={`${
                      location.pathname.includes("/incompletetests")
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                    } w-full flex items-center gap-3 rounded-lg px-3 py-2 text-left transition-all`}
                    title="Incomplete Tests"
                  >
                    <History className="h-4 w-4" />
                    <span className={`${sidebarOpen ? "" : "text-xs"}`}>
                      Incomplete Tests
                    </span>
                  </button>
                  <button
                    onClick={() => navigate("/performance")}
                    className={`${
                      location.pathname.includes("/performance")
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                    } w-full flex items-center gap-3 rounded-lg px-3 py-2 text-left transition-all`}
                    title="Performance"
                  >
                    <ChartBar className="h-4 w-4" />
                    <span className={`${sidebarOpen ? "" : "text-xs"}`}>
                      Performance
                    </span>
                  </button>
                  <button
                    onClick={() => navigate("/AccountSettings")}
                    className={`${
                      location.pathname.includes("/accountsettings")
                        ? "bg-white/10 text-white"
                        : "text-white/80 hover:bg-white/10 hover:text-white"
                    } w-full flex items-center gap-3 rounded-lg px-3 py-2 text-left transition-all`}
                    title="Account Settings"
                  >
                    <Settings className="h-4 w-4" />
                    <span className={`${sidebarOpen ? "" : "text-xs"}`}>
                      Account Settings
                    </span>
                  </button>
                </nav>
              </div>
              <div className="w-full flex flex-col items-start bg-secondaryDark p-2 items-center justify-center text-center">
                <span className="text-white">Expiration Date</span>
                <span className="text-white text-xs">{formattedDate}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex flex-col">
        {/* Header (Visible on mobile devices only) */}
        <header className="bg-primaryDark flex h-14 items-center border-b border-white/10 px-4 shadow-sm lg:hidden">
          <button className="flex items-center gap-2 font-semibold text-white">
            <img
              src={"images/dental.png"}
              className="w-6 h-6"
              onClick={toggleSidebar}
            />
          </button>
          <button
            className="ml-auto h-8 w-8 text-white hover:bg-white/10"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </button>
        </header>

        {/* Mobile Sidebar */}
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-50 flex">
            <div
              className="flex-1 bg-black opacity-50"
              role="button"
              aria-label="Close menu"
              onClick={() => setMobileMenuOpen(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setMobileMenuOpen(false);
                }
              }}
            ></div>
            <div className="w-64 bg-primaryDark from-blue-600 to-blue-800 p-4 flex flex-col justify-between">
              <nav className="grid gap-3 text-lg font-medium text-white">
                <button
                  onClick={() => {
                    navigate("/CreateTest");
                    setMobileMenuOpen(false);
                  }}
                  className={`${
                    location.pathname.includes("/createtest")
                      ? "bg-white/10 text-white"
                      : "text-white/80 hover:bg-white/10 hover:text-white"
                  } flex items-center gap-6 rounded-xl px-4 py-2 w-full text-left`}
                >
                  <PlusCircle className="h-5 w-5" />
                  Create Test
                </button>
                <button
                  onClick={() => {
                    navigate("/completetests");
                    setMobileMenuOpen(false);
                  }}
                  className={`${
                    location.pathname.includes("/completetests")
                      ? "bg-white/10 text-white"
                      : "text-white/80 hover:bg-white/10 hover:text-white"
                  } flex items-center gap-6 rounded-xl px-4 py-2 w-full text-left`}
                >
                  <History className="h-5 w-5" />
                  Complete Tests
                </button>
                <button
                  onClick={() => {
                    navigate("/incompletetests");
                    setMobileMenuOpen(false);
                  }}
                  className={`${
                    location.pathname.includes("/incompletetests")
                      ? "bg-white/10 text-white"
                      : "text-white/80 hover:bg-white/10 hover:text-white"
                  } flex items-center gap-6 rounded-xl px-4 py-2 w-full text-left`}
                >
                  <History className="h-5 w-5" />
                  Incomplete tests
                </button>
                <button
                  onClick={() => {
                    navigate("/Performance");
                    setMobileMenuOpen(false);
                  }}
                  className={`${
                    location.pathname.includes("/Performance")
                      ? "bg-white/10 text-white"
                      : "text-white/80 hover:bg-white/10 hover:text-white"
                  } flex items-center gap-6 rounded-xl px-4 py-2 w-full text-left`}
                >
                  <ChartBar className="h-5 w-5" />
                  Performance
                </button>
                <button
                  onClick={() => {
                    navigate("/AccountSettings");
                    setMobileMenuOpen(false);
                  }}
                  className={`${
                    location.pathname.includes("/accountsettings")
                      ? "bg-white/10 text-white"
                      : "text-white/80 hover:bg-white/10 hover:text-white"
                  } flex items-center gap-6 rounded-xl px-4 py-2 w-full text-left`}
                >
                  <Settings className="h-5 w-5" />
                  Account Settings
                </button>
              </nav>
              <div className="w-full flex flex-col items-start bg-secondaryDark p-2 items-center justify-center text-center">
                <span className="text-white">Expiration Date</span>
                <span className="text-white text-xs">{formattedDate}</span>
              </div>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
export default LayoutWrapper;

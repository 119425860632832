
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartAnnotation from "chartjs-plugin-annotation";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartAnnotation
);

const DonutChart = ({
  labels,
  backgroundColor,
  dataset,
  subContent,
  subTitle,
  primaryText,
  secondaryText,
  xmin,
  xmax,
  ymin,
  ymax,
  xValue,
  yValue,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: dataset,
        backgroundColor: backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      annotation: {
        annotations: {
          label1: {
            type: "label",
            xValue: xValue,
            yValue: yValue,
            content: [subContent],
            color: primaryText,
            font: {
              size: 28,
            },
            yAdjust: -5,
          },
          label2: {
            type: "label",
            xValue: xValue,
            yValue: yValue,
            content: [subTitle],
            color: secondaryText,
            font: {
              size: 18,
            },
            yAdjust: 20,
          },
        },
      },
    },
    cutout: "90%",
    scales: {
      x: {
        display: false, // Hide the x-axis
        min: xmin, // Set appropriate range
        max: xmax,
      },
      y: {
        display: false, // Hide the y-axis
        min: ymin, // Set appropriate range
        max: ymax,
      },
    },
  };

  return (
    <div className="w-[180px] h-[180x] relative flex items-center justify-center">
      {/*Overriding style here, to make chart fit,
          // @ts-expect-error. This works no need to add more params*/}
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonutChart;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testStartDate: undefined,
  testStartSeconds: undefined,
  createdTest: { QuestionsForTest: [], FirstQuestion: {} },
  examToReview: undefined,
  testExtraSeconds: 0, //300 seconds = 5 mins
  testOutOfTime: false,
  whenTestRanOutOfTime: undefined, //used to make sure that the extra time is given from when the button was clicked
};
const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setTestStart(state, action) {
      state.testStartDate = action.payload.testStartDate;
    },
    setTestStartSeconds(state, action) {
      state.testStartSeconds = action.payload.testStartSeconds;
      state.testStartDate = action.payload.testStartDate;
    },
    setCreatedTest(state, action) {
      state.createdTest = action.payload.createdTest;
      //Reset extra seconds on created test
      state.testExtraSeconds = 0;
      state.testOutOfTime = false;
      state.whenTestRanOutOfTime = undefined;
    },
    setReviewedExam(state, action) {
      state.examToReview = action.payload.examToReview;
    },
    addTestExtraSeconds(state, action) {
      state.testExtraSeconds += action.payload.testExtraSeconds;
      state.testOutOfTime = false;
      state.whenTestRanOutOfTime = undefined;
    },
    setTestOutOfTime(state, action) {
      state.testOutOfTime = action.payload.testOutOfTime;
      state.whenTestRanOutOfTime = action.payload.whenTestRanOutOfTime;
    },
  },
});

export const {
  setTestStart,
  setCreatedTest,
  setTestStartSeconds,
  setReviewedExam,
  addTestExtraSeconds,
  setTestOutOfTime,
} = testSlice.actions;
export default testSlice.reducer;
export const getTestStartSelector = (state) => state.test.testStartDate;
export const getTestStartSecondsSelector = (state) =>
  state.test.testStartSeconds;
export const getCreatedTest = (state) => state.test.createdTest;
export const getExamToReview = (state) => state.test.examToReview;
export const getTestExtraSeconds = (state) => state.test.testExtraSeconds;
export const getTestOutOfTime = (state) => state.test.testOutOfTime;
export const getWhenTestRanOutOfTime = (state) =>
  state.test.whenTestRanOutOfTime;

export default function Snbchart({ extraData, textSize, getHighlightedText }) {
  const values = extraData["Patient Values"];
  return (
    <div className="flex">
      <table className="border border-primaryDark">
        <tbody>
          {/*Labels*/}
          <tr className="vestibularDataRow">
            <td
              className={`border border-primaryDark  ${textSize.left} px-2`}
              data-id="demo_label"
            >
              <span data-id="container-break">
                {getHighlightedText("", "demo_label")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 font-bold`}
              data-id={"mean_label"}
            >
              <span data-id="container-break">
                {getHighlightedText("Mean", "mean_label")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 font-bold`}
              data-id={"patient_label"}
            >
              <span data-id="container-break">
                {getHighlightedText("Patient", "patient_label")}
              </span>
            </td>
          </tr>
          {/*SNA*/}
          <tr className="bg-primaryLight">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2`}
              data-id="sna_label"
            >
              <span data-id="container-break">
                {getHighlightedText("SNA", "sna_label")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"sna_mean"}
            >
              <span data-id="container-break">
                {getHighlightedText("81°", "sna_mean")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"sna_patient"}
            >
              <span data-id="container-break">
                {getHighlightedText(`${values["SNA"]}`, "sna_patient")}
              </span>
            </td>
          </tr>
          {/*SNB*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2`}
              data-id="snb_label"
            >
              <span data-id="container-break">
                {getHighlightedText("SNB", "snb_label")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"snb_mean"}
            >
              <span data-id="container-break">
                {getHighlightedText("79°", "snb_mean")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"snb_patient"}
            >
              <span data-id="container-break">
                {getHighlightedText(`${values["SNB"]}`, "snb_patient")}
              </span>
            </td>
          </tr>
          {/*Sella-Nasion to Mandibular Plane*/}
          <tr className="bg-primaryLight">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2`}
              data-id="sntmp_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Sella-Nasion to Mandibular Plane",
                  "sntmp_label"
                )}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left}  px-2 text-center`}
              data-id={"sntmp_mean"}
            >
              <span data-id="container-break">
                {getHighlightedText("32°", "sntmp_mean")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"sntmp_patient"}
            >
              <span data-id="container-break">
                {getHighlightedText(`${values["SN-MP"]}`, "sntmp_patient")}
              </span>
            </td>
          </tr>
          {/*Frankfort horizontal to mandibular plane*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold  px-2`}
              data-id="fhtmp_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Frankfort horizontal to mandibular plane",
                  "fhtmp_label"
                )}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"fhtmp_mean"}
            >
              <span data-id="container-break">
                {getHighlightedText("24°", "fhtmp_mean")}
              </span>
            </td>
            <td
              className={`border border-primaryDark ${textSize.left} px-2 text-center`}
              data-id={"fhtmp_patient"}
            >
              <span data-id="container-break">
                {getHighlightedText(`${values["FMA"]}`, "fhtmp_patient")}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

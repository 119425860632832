function ToggleButton({ text, checked, setChecked }) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        name={`${text}toggleButton`}
        onChange={(e) => {
          setChecked(e.target.value);
        }}
      />
      <div className="relative w-11 h-6 bg-secondaryBackground peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      <span className="ms-3 text-sm font-medium text-SecondaryText">
        {text}
      </span>
    </label>
  );
}

export default ToggleButton;

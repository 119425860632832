import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthData } from "../helpers/auth";

const customBaseQuery = fetchBaseQuery({
  baseUrl: "https://app.oscedental.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const loginData = JSON.parse(getAuthData());
    const tokenCookie = loginData?.token;
    //console.log("Got cookie in the form of..", tokenCookie);
    if (tokenCookie) {
      headers.set("TOKEN", `${tokenCookie}`);
      
    } else {
      console.warn("No user token found in parsed cookie", loginData);
    }

    if (endpoint === "getQuestionImageById") {
      headers.set("Content-Type", "image/png");
      //headers.set("Cache-Control", "private");
    } else {
      headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  tagTypes: ["Questions"],
  endpoints: () => ({}),
});

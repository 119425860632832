import "react-toastify/dist/ReactToastify.css";
import Modal from "../modal/Modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTokenInvalidated } from "../../store/login.slice";

const Unauthwrapper = ({ children }) => {
  const navigate = useNavigate();
  const tokenInvalidated = useSelector(getTokenInvalidated);
  return (
    <div>
      {children}
      <Modal
        isOpen={tokenInvalidated}
        onClose={() => {}}
        title={""}
        submit={undefined}
        onSubmit={undefined}
        fullWidth={false}
        closeHidden={true}
        pixelWidth={500}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <div className="flex items-center justify-center w-full">
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <span className="text-xl font-bold">
                You have been logged out
              </span>
              <span className="text-sm font-light text-gray-600">
                You are no longer logged into this session and will have to
                refresh and re-login.
              </span>
            </div>
            <div className="flex-grow space-y-4 overflow-y-auto my-2">
              <span className="text-xl font-bold">
                Common reasons you may see this message
              </span>
              <ul className="list-disc list-inside ">
                <li>You have signed in on another device</li>
                <li>You have been idle for too long</li>
                <li>
                  You have shared your account details with someone else, This
                  is not allowed. Please refer to our terms of service
                </li>
                <li>
                  In some cases, your account may have been compromised. If you
                  believe this is the case, change your password immediately.
                </li>
              </ul>
            </div>
            <button
              className="bg-primaryDark p-3 text-white"
              onClick={() => {
                navigate("/login");
              }}
            >
              Return to login
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Unauthwrapper;

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUserAuthenticated } from '../store/login.slice';

const ProtectedRoute = ({ children }) => {
	const isAuthenticated = useSelector(getUserAuthenticated);
	if (!isAuthenticated) {
		return <Navigate to="/login" replace />;
	}

	return children;
};

export default ProtectedRoute;

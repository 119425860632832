import { apiSlice } from "../apiSlice";

const secondaryUrl = "getquestionget.webapi";
const questionsExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionById: builder.mutation({
      query: ({ question_id }) => ({
        url: `${secondaryUrl}/`,
        method: "POST",
        body: { question_id: question_id }, // Pass the body here for POST
      }),
    }),
    getQuestionByQuestionId: builder.query({
      query: ({ question_id, choices }) => ({
        url: "getquestiononly.webapi/",
        headers: {
          "Question-id": question_id,
          "Answer-Choices":
            choices && choices[question_id] != undefined
              ? "[" + choices[question_id] + "]"
              : "",
        },
      }),
      // @ts-expect-error. Typescript hates redux I guess?
      providesTags: (result, error, { question_id }) => [
        { type: "Question", id: question_id },
      ],
    }),
    getQuestionImageById: builder.query({
      query: ({ question_id }) => ({
        url: `getQuestionMedia.webapi/?question_id=${question_id}`,
        headers: {
          "Question-id": question_id,
        },
        responseHandler: (response) => response.blob(),
        params: {
          cacheBusting: question_id,
        },
      }),
    }),
    getFullQuestionById: builder.query({
      query: ({ question_id }) => ({
        url: "getquestionget.webapi/",
        headers: {
          "Question-id": question_id,
        },
      }),
    }),
    getModel: builder.query({
      query: ({ part }) => ({
        url: "3dimage.webapi/",
        headers: {
          Image: part,
        },
      }),
    }),
  }),
});

export const {
  useGetQuestionByIdMutation,
  useGetQuestionByQuestionIdQuery,
  useGetQuestionImageByIdQuery,
  useGetFullQuestionByIdQuery,
  useGetModelQuery,
} = questionsExtendedSlice;

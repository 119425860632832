import { Printer } from "lucide-react";
import { useGetuserstatsQuery } from "../api/accountSlice/accountSlice";
import BellCurve from "../components/bellCurveChart/bellCurve";
import DonutChart from "../components/donutChart/donutChart";
import { useSelector } from "react-redux";
import { getUserDataTheme } from "../store/login.slice";
import { darkThemes } from "../constants/themes";
import PerformanceOverTimeChart from "../components/performanceOverTimeChart/performanceovertimechart";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";

function Dashboard() {
  const theme = useSelector(getUserDataTheme);
  const themeDark = darkThemes.includes(theme);

  let primary = "hsl(0, 0, 0)";
  let secondary = "hsl(0, 0%, 30%)";

  if (themeDark) {
    primary = "hsl(0, 0%, 100%)";
    secondary = "hsl(0, 0%, 100%)";
  }

  const { data, isFetching, isError,error } = useGetuserstatsQuery({});
  const handlePrint = () => {
    window.print(); // Triggers the browser's print dialog for the whole page
  };
  
  let tokenspoilcheck= undefined
  if (error != undefined && 'status' in error) {
    tokenspoilcheck=error?.status
  }
  useTokenSpoilCheck(tokenspoilcheck);

  if (isFetching || isError) return <></>;
  const TotalQuestions = data?.QbankUsage.TotalQuestions;
  const UsedQuestions = data?.QbankUsage.UsedQuestions;
  const UnusedQuestions = data?.QbankUsage.UnusedQuestions;
  const TotalCorrect = data?.Scores.TotalCorrect;
  const TotalIncorrect = data?.Scores.TotalIncorrect;
  const TotalOmitted = data?.Scores.TotalOmitted;
  const categories = data?.Categories;
  let Percentage = (TotalCorrect / (TotalIncorrect + TotalOmitted)) * 100;
  if(TotalCorrect==0 && TotalIncorrect==0 && TotalOmitted==0 )
  {
    Percentage=0
  }
  const UsagePercentage = (UsedQuestions / TotalQuestions) * 100;

  return (
    <div>
      <div className="border-b border-gray-300 p-4 flex justify-between text-primaryText">
        <span>Statistics</span>{" "}
        <button
          className="flex gap-3 text-[#6196f5] font-semibold"
          onClick={handlePrint}
        >
          <Printer />
          Print
        </button>
      </div>
      <div className="bg-primaryBackground m-4 rounded">
        {/*First row*/}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mx-5">
          <div className="p-4 flex flex-col space-y-20 items-center">
            <DonutChart
              labels={["Correct", "Incorrect", "Omitted"]}
              backgroundColor={["#69c869", "#fcdede", "#e1ebfd"]}
              dataset={[TotalCorrect, TotalIncorrect, TotalOmitted]}
              subTitle={"Correct"}
              subContent={`${Percentage.toPrecision(2)}%`}
              primaryText={primary}
              secondaryText={secondary}
              xmin={45}
              xmax={100}
              ymin={10}
              ymax={100}
              xValue={2.5}
              yValue={60}
            />
            <DonutChart
              labels={["Used", "Unused"]}
              backgroundColor={["#1e88e5", "#e3e3e3"]}
              dataset={[UsedQuestions, UnusedQuestions]}
              subTitle={"Used"}
              subContent={`${UsagePercentage.toFixed(1)}%`}
              primaryText={primary}
              secondaryText={secondary}
              xmin={0}
              xmax={1}
              ymin={10}
              ymax={100}
              xValue={undefined}
              yValue={undefined}
            />
          </div>
          <div className="p-4 flex flex-col text-primaryText">
            <div className="flex flex-col space-y-10 ">
              <div>
                <h2 className="text-lg font-bold mb-2">Your Score</h2>
                <ul className="space-y-2">
                  <li className="flex justify-between border-b pb-2">
                    <span>Total Correct</span>
                    <span className="  px-2 py-1 ">
                      {data?.Scores?.TotalCorrect}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Total Incorrect</span>
                    <span className="  px-2 py-1 ">
                      {data?.Scores?.TotalIncorrect}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Total Omitted</span>
                    <span className="  px-2 py-1 ">
                      {data?.Scores?.TotalOmitted}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-bold mb-2">QBank Usage</h2>
                <ul className="space-y-2">
                  <li className="flex justify-between border-b pb-2">
                    <span>Used Questions</span>
                    <span className="  px-2 py-1 ">
                      {data?.QbankUsage?.UsedQuestions}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Unused Questions</span>
                    <span className="  px-2 py-1 ">
                      {data?.QbankUsage?.UnusedQuestions}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Total Questions</span>
                    <span className="  px-2 py-1 ">
                      {data?.QbankUsage?.TotalQuestions}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-4 text-primaryText">
            <div className="flex flex-col space-y-10">
              <div>
                <h2 className="text-lg font-bold mb-2">Answer Changes</h2>
                <ul className="space-y-2">
                  <li className="flex justify-between border-b pb-2">
                    <span>Correct to incorrect</span>
                    <span className="  px-2 py-1 ">
                      {data?.AnswerChanges?.CorrectToIncorrect}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Incorrect to Correct</span>
                    <span className="  px-2 py-1 ">
                      {data?.AnswerChanges?.IncorrectToCorrect}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Incorrect to Incorrect</span>
                    <span className="  px-2 py-1 ">
                      {data?.AnswerChanges?.IncorrectToIncorrect}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-bold mb-2">Test Count</h2>
                <ul className="space-y-2">
                  <li className="flex justify-between border-b pb-2">
                    <span>Tests Created</span>
                    <span className="  px-2 py-1 ">
                      {data?.Tests?.TestsCreated}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Tests Completed</span>
                    <span className="  px-2 py-1 ">
                      {data?.Tests?.TestsCompleted}
                    </span>
                  </li>
                  <li className="flex justify-between border-b pb-2">
                    <span>Suspended Tests</span>
                    <span className="  px-2 py-1 ">
                      {data?.Tests?.IncompleteTests}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*second row*/}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-5 text-primaryText">
          {false && (
            <div className="pl-4 h-[267px] w-[100%] flex justify-center">
              (
              <BellCurve myPercentile={data?.Performance?.PercentileRank} />)
            </div>
          )}
          {/*Performance*/}
          <div className="mt-6 p-4">
            <h2 className="text-lg font-bold mb-2">Performance</h2>
            <ul className="space-y-2">
              <li className="flex justify-between border-b pb-2">
                <span>Your Average First Attempt Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Scores?.AverageFirstAttemptScore}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>Your Average Every Attempts Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.YourAverageScore}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>All User's First Attempt Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.GlobalFirstAttemptAverage}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>All User's Every Attempt Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.GlobalAverageScore}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>Your Median Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.YourMedianScore}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>All User's Median Score</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.GlobalMedianScore}%
                </span>
              </li>
              <li className="flex justify-between border-b pb-2">
                <span>Percentile Rank</span>
                <span className="  px-2 py-1 ">
                  {data?.Performance?.PercentileRank}%
                </span>
              </li>
            </ul>
          </div>
          {/*Average by category*/}
          <div className="mt-6 p-4">
            <h2 className="text-lg font-bold mb-2">Averages By Category</h2>
            <ul className="space-y-2 h-[335px] overflow-auto">
              {
                <div>
                  <ul className="space-y-2">
                    {Object.entries(categories.AverageScoreByCategory).map(
                      ([key, value], index) => (
                        <li
                          className="flex justify-between border-b pb-2"
                          key={`${index} ${value}`}
                        >
                          <span>{key}</span>
                          <span className="px-2 py-1 ">{`${value}`}%</span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              }
            </ul>
          </div>
        </div>
        {/*Best Worst category*/}
        <div className="mt-6 p-4 max-w-[400px] mx-auto text-primaryText">
          <h2 className="text-lg font-bold mb-2 text-center">
            Best/Worst Category
          </h2>
          <ul className="space-y-2">
            <li
              className="flex justify-between border-b pb-2"
              key={"bestcategory"}
            >
              <span>{"Best Category"}</span>
              <span className="text-right">{categories.BestCategory}</span>
            </li>
            <li
              className="flex justify-between border-b pb-2"
              key={"worstcategory"}
            >
              <span>{"Worst Category"}</span>
              <span className="text-right">{categories.WorstCategory}</span>
            </li>
          </ul>
        </div>
        {/*third row*/}
        <div className="grid lg:grid-cols-1 gap-4 mx-5">
          <div className="flex flex-col">
            <div className="bg-primaryDark p-5 rounded">
              <h3 className="text-xl font-semibold text-contrastText">
                Performance Over Time
              </h3>
            </div>
            <div className="">
              <PerformanceOverTimeChart
                dailyPerformance={data.Performance.DailyPerformance || {}}
              />
            </div>
            <div className="flex flex-row justify-between mt-3">
              {false && (
                <div className="bg-secondaryLight p-3 border-2 rounded border-primaryDark flex flex-row justify-between w-[50%] items-center">
                  <span className="text-primaryText">
                    Average Improvement Per Attempt
                  </span>
                  <span className="text-primaryText">??</span>
                </div>
              )}
              <div className="bg-secondaryLight p-3 space-x-2 rounded border-primaryDark flex flex-row justify-between w-[100%] items-center">
                <span className="text-primaryText">
                  Average Improvement Per Day
                </span>
                <span className="text-primaryText">
                  {data.Performance.AverageImprovement}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

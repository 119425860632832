export default function USPatientTable({ currentQuestion, textSize, getHighlightedText }) {
  return (
    <table className="min-w-full table-auto border border-primaryDark">
      <thead>
        <tr>
          <th
            className={`px-4 py-2 border border-primaryDark text-left font-normal ${textSize.left}`}
            colSpan={2}
          >
            {`${currentQuestion.GetQuestion.patient_age} year old ${currentQuestion.GetQuestion.patient_gender}`}
          </th>
        </tr>
      </thead>
      <tbody>
        {currentQuestion.GetQuestion.chief_complaint && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="chief_complaint_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Chief Complaint/ Reason for Visit:",
                  "chief_complaint_label"
                )}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="chief_complaint_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  `"${currentQuestion.GetQuestion.chief_complaint}"`,
                  "chief_complaint_text"
                )}
              </span>
            </td>
          </tr>
        )}
        {currentQuestion.GetQuestion.dental_history && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="dental_history_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Dental History:", "dental_history_label")}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="dental_history_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  currentQuestion.GetQuestion.dental_history,
                  "dental_history_text"
                )}
              </span>
            </td>
          </tr>
        )}
        {currentQuestion.GetQuestion.medical_history && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="medical_history_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Medical History:",
                  "medical_history_label"
                )}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="medical_history_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  currentQuestion.GetQuestion.medical_history,
                  "medical_history_text"
                )}
              </span>
            </td>
          </tr>
        )}
        {currentQuestion.GetQuestion.clinical_findings && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="clinical_findings_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Clinical Findings:",
                  "clinical_findings_label"
                )}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="clinical_findings_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  currentQuestion.GetQuestion.clinical_findings,
                  "clinical_findings_text"
                )}
              </span>
            </td>
          </tr>
        )}
        {currentQuestion.GetQuestion.radiographic_findings && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="radiographic_findings_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Radiographic Findings:",
                  "radiographic_findings_label"
                )}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="radiographic_findings_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  currentQuestion.GetQuestion.radiographic_findings,
                  "radiographic_findings_text"
                )}
              </span>
            </td>
          </tr>
        )}
        {currentQuestion.GetQuestion.histological_findings && (
          <tr>
            <td
              className={`px-4 py-2 border border-primaryDark w-1/4 bg-primaryLight ${textSize.left}`}
              data-id="histological_findings_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Histological Findings:",
                  "histological_findings_label"
                )}
              </span>
            </td>
            <td
              className={`px-4 py-2 border border-primaryDark ${textSize.left}`}
              data-id="histological_findings_text"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  currentQuestion.GetQuestion.histological_findings,
                  "histological_findings_text"
                )}
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

import { Check, X } from "lucide-react";
import CheckBox from "../checkbox/checkbox";

function MultipleChoiceAnswerBlock({processQuestionText,GetShuffledAnswerArray,currentQuestion,completedAnswerArray,examinationState,createdExam,handleAnswerChecked,processQuestionLabel,getHighlightedText,showChecksAndXes,showExplanations,textSize}) {
  return (
    <div className="leftReset">
      <span className="mb-3">
        {processQuestionText(currentQuestion.GetQuestion.question_text)}
      </span>
      <div>
        {GetShuffledAnswerArray().map((answer, index) => {
          const answerDataId = `answerChoice-${answer?.answer_serial}`;
          return (
            <div className="mb-3" key={`answer.answer_serial ${index}`}>
              <span className="flex space-x-3" key={answer.answer_serial}>
                <span data-id={answerDataId} className="flex">
                  <div className="self-start mr-3">
                    <CheckBox
                      checked={
                        completedAnswerArray == undefined
                          ? examinationState.answersById[
                              createdExam.QuestionsForTest[
                                examinationState.currentQuestionIndex
                              ]
                            ]?.includes(answer?.answer_serial)
                          : completedAnswerArray.includes(answer?.answer_serial)
                      }
                      setChecked={() => {
                        handleAnswerChecked(answer.answer_serial);
                      }}
                      text={""}
                    />
                  </div>
                  <div className="self-start mr-3">
                    <span>{processQuestionLabel(index + 1)}</span>
                  </div>
                  <span
                    className={` ${textSize.right} flex space-x-5`}
                    data-id="container-break"
                  >
                    {getHighlightedText(answer?.answer_choice, answerDataId)}
                    {showChecksAndXes && answer.is_correct && (
                      <Check className="text-[#175317]" />
                    )}
                    {showChecksAndXes &&
                      completedAnswerArray != undefined &&
                      !answer.is_correct &&
                      completedAnswerArray.includes(answer?.answer_serial) && (
                        <X className="text-[#411414]" />
                      )}
                  </span>
                </span>
              </span>
              {showExplanations && (
                <span className="text-xs italic">{answer.explanation}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MultipleChoiceAnswerBlock;

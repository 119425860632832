import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ChartAnnotation from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotation
);

const generateNormalDistributionData = (mean, stdDev, numPoints) => {
  const xValues = [];
  const yValues = [];
  const step = (6 * stdDev) / numPoints;

  for (let i = -3 * stdDev; i <= 3 * stdDev; i += step) {
    const x = mean + i;
    const y =
      (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
      Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
    xValues.push(x);
    yValues.push(y);
  }

  return { xValues, yValues };
};

const BellCurve = ({ myPercentile }) => {
  const mean = 0;
  const stdDev = 1;
  const numPoints = 100;

  const { xValues, yValues } = generateNormalDistributionData(
    mean,
    stdDev,
    numPoints
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        borderColor: "rgb(187, 187, 187)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 0.8,
        },
        offset: false,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          verticalLine: {
            type: "line",
            xMin: myPercentile,
            xMax: myPercentile,
            yMax: 0.45,
            borderColor: "rgb(146, 203, 249)",
            borderWidth: 1.5,
          },
          label1: {
            type: "label",
            xValue: 90,
            yValue: 0.12,
            color: "rgb(105, 200, 105)",
            content: ["90th"],
            font: {
              size: 18,
            },
          },
          label2: {
            type: "label",
            xValue: myPercentile,
            yValue: 0.5,
            color: "rgb(146, 203, 249)",
            content: [`${myPercentile}th`],
            font: {
              size: 18,
            },
          },
          point2: {
            type: "point",
            xValue: 90,
            yValue: 0.04,
            backgroundColor: "white",
            borderColor: "rgb(105, 200, 105)",
            radius: 13,
            z: 1,
          },
          point: {
            type: "point",
            xValue: 90,
            yValue: 0.04,
            backgroundColor: "rgb(105, 200, 105)",
            borderColor: "rgb(105, 200, 105)",
            radius: 8,
            z: 2,
          },
        },
      },
    },
    hover: {
      mode: null,
    },
  };

  return (
    <>
      {/*
          // @ts-expect-error. This works no need to add more params*/}
      <Line data={data} options={options} />
    </>
  );
};

export default BellCurve;

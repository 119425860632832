import ReactDOM from "react-dom";
import ThemeWrapper from "../Wrappers/themeWrapper";
import { XSquare } from "lucide-react";

const Modal = ({
  isOpen,
  onClose,
  submit,
  closeHidden,
  onSubmit,
  title,
  fullWidth,
  children,
  pixelWidth,
  submitText,
  cancelText,
  xClose,
  submitDark,
  closeDark,
  buttonsSpaced,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ThemeWrapper>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div
          className={`bg-secondaryLight rounded-lg p-6 flex flex-col overflow-x-auto ${
            fullWidth
              ? "w-full"
              : pixelWidth != undefined
              ? `w-[${pixelWidth}px]`
              : "w-96"
          }`}
        >
          <div className="flex justify-between w-full">
            <h2 className="text-lg font-bold mb-4">{title}</h2>
            {xClose && (
              <XSquare className="w-6 h-6 cursor-pointer" onClick={onClose} />
            )}
          </div>
          {children}
          <div
            className={`flex space-x-2 ${
              buttonsSpaced ? "justify-between" : "justify-end"
            }`}
          >
            {!closeHidden && (
              <button
                onClick={onClose}
                className={`${
                  closeDark ? "bg-secondaryDark" : "bg-primaryDark"
                } text-white px-4 py-2`}
              >
                {cancelText ? cancelText : "Close"}
              </button>
            )}
            {submit && (
              <button
                onClick={() => {
                  onSubmit();
                }}
                className={`${
                  submitDark ? "bg-secondaryDark" : "bg-primaryDark"
                } text-white px-4 py-2`}
              >
                {submitText ? submitText : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </ThemeWrapper>,
    document.getElementById("modal-root")
  );
};

export default Modal;

export default function MoyerPredictionChart({
  getHighlightedText,
}) {
  return (
    <div className="flex w-[720px]">
      <table className="border border-primaryDark moyerTable">
        <tbody>
          <tr>
            <td
              className="underline font-bold border border-primaryDark text-center pb-4"
              colSpan={12}
              data-id="moyerHeader"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  `Moyer's
                prediction values (75% level)`,
                  "moyerHeader"
                )}
              </span>
            </td>
          </tr>
          <tr className="moyerChartRow">
            <td
              colSpan={2}
              className="moyerChartHeader"
              data-id="MandibularIncisorHeaderOne"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  `Total Mandibular-Incisor Width`,
                  "MandibularIncisorHeaderOne"
                )}
              </span>
            </td>
            {Array.from([
              19.5, 20.0, 20.5, 21.0, 21.5, 22.0, 22.5, 23.0, 23.5, 24.0,
            ]).map((value, index) => (
              <td key={index} data-id={`mandibularOneValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `mandibularOneValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
          <tr className="moyerChartEmptyRow">
            {Array.from({ length: 12 }).map((_, index) => (
              <td key={index}></td>
            ))}
          </tr>
          <tr className="moyerChartRowShort">
            <td rowSpan={2}>
              <div
                className="moyerChartSplitHeader"
                data-id="PredictedHeaderOne"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Predicted Width of canine and premolars",
                    `PredictedHeaderOne`
                  )}
                </span>
              </div>
            </td>
            <td className="moyerChartSplitHeader" data-id="MaxillaHeaderOne">
              <span data-id="container-break">
                {getHighlightedText("Maxilla", `MaxillaHeaderOne`)}
              </span>
            </td>
            {Array.from([
              20.6, 20.9, 21.2, 21.3, 21.8, 22.0, 22.3, 22.6, 22.9, 23.1,
            ]).map((value, index) => (
              <td key={index} data-id={`maxillaOneValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `maxillaOneValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
          <tr className="moyerChartRow">
            <td className="moyerChartSplitHeader" data-id="MandibleHeaderOne">
              <span data-id="container-break">
                {getHighlightedText("Mandible", `MandibleHeaderOne`)}
              </span>
            </td>
            {Array.from([
              20.1, 20.4, 20.7, 21.0, 21.3, 21.6, 21.9, 22.2, 22.5, 22.8,
            ]).map((value, index) => (
              <td key={index} data-id={`MandibleOneValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `MandibleOneValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
          {/* Part two */}
          <tr className="moyerChartEmptyRow border border-primaryDark">
            {Array.from({ length: 12 }).map((_, index) => (
              <td key={index}></td>
            ))}
          </tr>
          <tr className="moyerChartRow">
            <td
              colSpan={2}
              className="moyerChartHeader"
              data-id="MandibularIncisorHeaderTwo"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  `Total Mandibular-Incisor Width`,
                  "MandibularIncisorHeaderTwo"
                )}
              </span>
            </td>
            {Array.from([
              24.5, 25.0, 25.5, 26.0, 26.5, 27.0, 27.5, 28.0, 28.5, 29.0,
            ]).map((value, index) => (
              <td key={index} data-id={`mandibularTwoValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `mandibularTwoValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
          <tr className="moyerChartEmptyRow">
            {Array.from({ length: 12 }).map((_, index) => (
              <td key={index}></td>
            ))}
          </tr>
          <tr className="moyerChartRowShort">
            <td rowSpan={2}>
              <div
                className="moyerChartSplitHeader"
                data-id="PredictedHeaderTwo"
              >
                <span data-id="container-break">
                  {getHighlightedText(
                    "Predicted Width of canine and premolars",
                    `PredictedHeaderTwo`
                  )}
                </span>
              </div>
            </td>
            <td className="moyerChartSplitHeader" data-id="MaxillaHeaderTwo">
              <span data-id="container-break">
                {getHighlightedText("Maxilla", `MaxillaHeaderTwo`)}
              </span>
            </td>
            {Array.from([
              23.5, 23.7, 24.0, 24.2, 24.5, 24.8, 25.0, 25.3, 25.6, 25.9,
            ]).map((value, index) => (
              <td key={index} data-id={`maxillaTwoValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `maxillaTwoValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
          <tr className="moyerChartRow">
            <td className="moyerChartSplitHeader" data-id="MandibleHeaderTwo">
              <span data-id="container-break">
                {getHighlightedText("Mandible", `MandibleHeaderTwo`)}
              </span>
            </td>
            {Array.from([23.1,23.4,23.7,24.0,24.3,24.6,24.8,25.1,25.4,25.7]).map((value, index) => (
              <td key={index} data-id={`MandibleTwoValue${index}`}>
                <span data-id="container-break">
                  {getHighlightedText(value, `MandibleTwoValue${index}`)}
                </span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

import { useRef } from "react";

function CheckBox({ text, checked, setChecked }) {
  const idRef = useRef(
    `hs-checked-checkbox-id-${Math.random().toString(36).substr(2, 9)}`
  );
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        className="w-5 h-5 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
        id={idRef.current}
        checked={!!checked}
        onChange={() => {}}
        onClick={(event) => {
          event.stopPropagation();
          setChecked(!checked);
        }}
      />

      {text && (
        <label
          htmlFor={idRef.current}
          className="text-lg text-gray-500 ms-3 dark:text-neutral-400"
        >
          {text}
        </label>
      )}
    </div>
  );
}

export default CheckBox;

export default function DentalExaminationChart({
  extraData,
  textSize,
  getHighlightedText,
}) {
  const keys = Object.keys(extraData);
  return (
    <div className="flex">
      <table className="border border-primaryDark">
        <tbody>
          {/*Labels*/}
          <tr className="bg-primaryLight font-bold h-[50px]">
            <td
              className={`border border-primaryDark ${textSize.left} px-2`}
              data-id="empty_label"
            >
              <span data-id="container-break">
                {getHighlightedText("", "empty_label")}
              </span>
            </td>
            {keys.map((key) => {
              return (
                <td
                  key={key}
                  className={`border border-primaryDark ${textSize.left} px-2 text-center`}
                  data-id={key}
                >
                  <span data-id="container-break">
                    {getHighlightedText(key, { key })}
                  </span>
                </td>
              );
            })}
          </tr>
          {/*probing*/}
          <tr className="">
            <td className="border-r border-primaryDark">
              <tr>
                <td
                  className={`${textSize.left} font-bold px-2 bg-primaryLight border-r border-primaryDark h-[60px] content-start`}
                  data-id="probing_label"
                >
                  <span data-id="container-break">
                    {getHighlightedText("Probing", "probing_label")}
                  </span>
                </td>

                <td
                  className={`${textSize.left} font-bold bg-primaryLight p-0`}
                  data-id="vestibular_lingual_column"
                >
                  <div className="flex flex-col text-center">
                    <div
                      data-id="vestibular_label"
                      className="pb-1 px-4 border-b border-primaryDark"
                    >
                      <span data-id="container-break">
                        {getHighlightedText("Vestibular", "vestibular_label")}
                      </span>
                    </div>
                    <div data-id="lingual_label" className="px-4">
                      <span data-id="container-break">
                        {getHighlightedText("Lingual", "lingual_label")}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </td>
            {keys.map((key) => {
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*vestibular*/}
                    <div className="px-4 border-b border-primaryDark flex justify-center space-x-5">
                      <div
                        data-id={"vestibular_label_one"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.vestibular[0],
                            "vestibular_label_one"
                          )}
                        </span>
                      </div>
                      <div
                        data-id={"vestibular_label_two"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.vestibular[1],
                            "vestibular_label_two"
                          )}
                        </span>
                      </div>
                      <div
                        data-id={"vestibular_label_three"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.vestibular[2],
                            "vestibular_label_three"
                          )}
                        </span>
                      </div>
                    </div>
                    {/*Lingual*/}
                    <div className="px-4 flex justify-center space-x-5">
                      <div
                        data-id={"lingual_label_one"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.lingual[0],
                            "lingual_label_one"
                          )}
                        </span>
                      </div>
                      <div
                        data-id={"lingual_label_two"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.lingual[1],
                            "lingual_label_two"
                          )}
                        </span>
                      </div>
                      <div
                        data-id={"lingual_label_three"}
                        className="dentalExamMultiCell"
                      >
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].probing.lingual[2],
                            "lingual_label_three"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Mobility*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="mobility_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Mobility", "mobility_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `mobility_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*mobility*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(extraData[key].mobility, dataId)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Percussion*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="percussion_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Percussion", "percussion_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `percussion_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*percussion*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].percussion,
                            dataId
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Palpation*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="palpation_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Palpation", "palpation_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `palpation_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*palpation*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(extraData[key].palpation, dataId)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Cold Test*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="coldtest_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Cold Test", "coldtest_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `cold_test_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*Cold test*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(extraData[key].cold_test, dataId)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Electric pulp test*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="electricpulptest_label"
            >
              <span data-id="container-break">
                {getHighlightedText(
                  "Electric Pulp Test",
                  "electricpulptest_label"
                )}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `electric_pulp_test_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*Electric pulp test*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].electric_pulp_test,
                            dataId
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Bite Test*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="bitetest_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Bite Test", "bitetest_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `bite_test_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*bite test**/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(extraData[key].bite_test, dataId)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Heat Test*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="heattest_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Heat Test", "heattest_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `heat_test_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*Heat Test**/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(extraData[key].heat_test, dataId)}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
          {/*Discolouration*/}
          <tr className="">
            <td
              className={`border border-primaryDark  ${textSize.left} font-bold px-2 bg-primaryLight`}
              data-id="discolouration_label"
            >
              <span data-id="container-break">
                {getHighlightedText("Discolouration", "discolouration_label")}
              </span>
            </td>
            {keys.map((key, index) => {
              const dataId = `discoloration_label_${index}`;
              return (
                <td
                  key={key}
                  className={`border border-primaryDark  ${textSize.left} p-0 text-center`}
                >
                  <div className="flex flex-col text-center">
                    {/*Discolouration*/}
                    <div className="px-4 border-primaryDark flex justify-center space-x-5">
                      <div data-id={dataId} className="dentalExamMultiCell">
                        <span data-id="container-break">
                          {getHighlightedText(
                            extraData[key].discoloration,
                            dataId
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

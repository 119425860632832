import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDataTheme } from "../../store/login.slice";
import { themes, themelessRoutes } from "../../constants/themes";
const contextClass = {
  success: "bg-primaryDark",
  error: "bg-primaryDark",
  info: "bg-primaryDark",
  warning: "bg-primaryDark",
  default: "bg-primaryDark",
  dark: "bg-primaryDark font-gray-300",
};

const ToastWrapper = ({ children }) => {
  const theme = useSelector(getUserDataTheme);
  const currentPath = window.location.pathname;
  const themeDisabled = themelessRoutes.includes(currentPath);
  return (
    <>
      {themeDisabled ? (
        <div>
          {children} <ToastContainer pauseOnFocusLoss={true} />
        </div>
      ) : (
        <div
          className={
            themeDisabled
              ? ""
              : `theme-${!themes.includes(theme) ? "default" : theme}`
          }
        >
          {children}{" "}
          <ToastContainer
            toastClassName={(context) =>
              contextClass[context?.type || "default"] +
              " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
            }
            progressClassName={"fancy-progress-bar"}
            pauseOnFocusLoss={true}
          />
        </div>
      )}
    </>
  );
};
export default ToastWrapper;

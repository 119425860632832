export default function MoyerInputChart({
  getHighlightedText,
}) {
  return (
    <div className="flex moyerTable">
      <table className="moyerInputTable">
        <tbody>
          <tr className="moyerInputRow">
            <td data-id="maxillaryWidthLabel">
              <span data-id="container-break">
                {getHighlightedText(
                  `Total maxillary incisor width`,
                  "maxillaryWidthLabel"
                )}
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr className="moyerInputRow">
            <td data-id="mandibularWidthLabel">
              <span data-id="container-break">
                {getHighlightedText(
                  `Total mandibular incisor width`,
                  "mandibularWidthLabel"
                )}
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr className="moyerInputRow">
            <td data-id="maxillaryarchLabel">
              <span data-id="container-break">
                {getHighlightedText(
                  `Maxillary arch length`,
                  "maxillaryarchLabel"
                )}
              </span>
            </td>
            <td>
            </td>
          </tr>
          <tr className="moyerInputRow">
            <td data-id="mandibulararchLabel">
              <span data-id="container-break">
                {getHighlightedText(
                  `Mandibular arch length`,
                  "mandibulararchLabel"
                )}
              </span>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

import { useSelector } from "react-redux";
import { getUserDataTheme } from "../../store/login.slice";
import { themes } from "../../constants/themes";

const ThemeWrapper = ({ children }) => {
  const theme = useSelector(getUserDataTheme);

  return (
    <div
      className={`theme-${!themes.includes(theme) ? "default" : theme}`}
      id={"themeWrapper"}
    >
      {children}
    </div>
  );
};
export default ThemeWrapper;

export function getDistanceToStartOfContainerBreak(container) {
  const getSortingOrderNode = (node) => {
    while (node) {
      if (
        node.getAttribute &&
        node.getAttribute("data-id") !== null &&
        node.getAttribute("data-id") !== "container-break" &&
        node.getAttribute("data-id").includes("sortingOrder")
      ) {
        return node;
      }
      node = node.parentNode;
    }
    return null; // Return null if no parent found
  };
  const sortingOrderNode = getSortingOrderNode(container);
  let tempElement = container;
  let breakElement = null;
  while (tempElement.parentElement) {
    tempElement = tempElement.parentElement;

    if (tempElement?.getAttribute("data-id") == "container-break") {
      breakElement = tempElement;
    }
  }

  if (breakElement == null) {
    throw new Error("Something went wrong");
  } else {
  }

  let extraDistance = 0;

  if (breakElement.children.length != 0) {
    for (let i = 0; i < breakElement.children.length; i++) {
      const element = breakElement.children[i];
      if (element == sortingOrderNode) {
        break;
      }

      extraDistance += element.textContent.length;
    }
  }

  return extraDistance;
}

export function processQuestionLabel(serial: number) {
  let label = "";
  let num = serial;

  while (num > 0) {
    // Adjust for zero-based index and find current letter
    const charCode = ((num - 1) % 26) + "A".charCodeAt(0);
    label = String.fromCharCode(charCode) + label;
    num = Math.floor((num - 1) / 26);
  }

  return label;
}

export function processQuestionText(questionText: string) {
  let parts = [];
  let text = "";
  if (questionText.includes("Select ONE OR MORE")) {
    parts = questionText.split("Select ONE OR MORE");
    text = "Select ONE OR MORE";
  } else if (questionText.includes("Select ONE correct answer")) {
    parts = questionText.split("Select ONE correct answer");
    text = "Select ONE correct answer";
  } else {
    return <span>{questionText}</span>;
  }

  return (
    <span>
      {parts[0]}
      <span className="font-bold">{text}</span>
      {parts[1]}
    </span>
  );
}

export function getSelectedContainers(range) {
  let commonAncestor = range.commonAncestorContainer;
  let containers = new Set(); // Use Set to prevent duplicates
  if (commonAncestor.nodeType === Node.TEXT_NODE) {
    // Add commonAncestor if it intersects with the range
    if (range.intersectsNode(commonAncestor)) {
      containers.add(commonAncestor);
    }
  } else {
    let walker = document.createTreeWalker(
      commonAncestor,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, // Show both elements and text nodes
      {
        acceptNode: function (node) {
          if (range.intersectsNode(node)) {
            return NodeFilter.FILTER_ACCEPT;
          }

          return NodeFilter.FILTER_REJECT;
        },
      }
    );

    let currentNode = walker.currentNode;

    while (currentNode) {
      currentNode = walker.nextNode();
      if (currentNode && currentNode.nodeType === Node.TEXT_NODE) {
        containers.add(currentNode); // Set ensures no duplicates
      }
    }
  }
  return Array.from(containers); // Convert Set to Array
}

export const getUnmodifiedHighlightedText = (text) => {
  // Return a single element that represents the entire text with highlights
  return <span>{text}</span>;
};

export function GetUnmodifiedAnswerArray(answerArray) {
  return answerArray;
}

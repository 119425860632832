import { getReviewColors } from "../../helpers/formatters";
import {
  testgrayBackground,
  testgrayText,
  testgreenBackground,
  testgreenText,
  testRedBackground,
  testRedText,
} from "../../constants/test";
import { useEffect } from "react";

function PerscriptionAnswerBlock({
  examinationState,
  setExaminationState,
  textSize,
  currentQuestionId,
  reviewMode,
  ShowAnswerMode,
  ShowMyAnswerMode,
  reviewData,
}) {
  useEffect(() => {
    if (
      currentQuestionId &&
      !examinationState?.prescriptions?.[currentQuestionId] &&
      setExaminationState
    ) {
      setExaminationState((prevState) => ({
        ...prevState,
        prescriptions: {
          ...prevState.prescriptions,
          [currentQuestionId]: [
            {
              drug: "",
              strength: "",
              disp: 0,
              sig: "",
              repeat: 0,
            },
          ],
        },
      }));
    }
  }, [currentQuestionId, examinationState, setExaminationState]);

  const currentPerscription =
    examinationState?.prescriptions?.[currentQuestionId]?.[0];

  // Update the prescription in the state, preventing default behavior
  const handleInputChange = (field, value, event) => {
    event.preventDefault();
    event.stopPropagation();

    // Check if field is 'disp' or 'repeat', then parse value as a number
    const updatedValue =
      field === "disp" || field === "repeat" ? Number(value) : value;
    if (setExaminationState) {
      setExaminationState((prevState) => ({
        ...prevState,
        prescriptions: {
          ...prevState.prescriptions,
          [currentQuestionId]: [
            {
              ...prevState.prescriptions[currentQuestionId]?.[0],
              [field]: updatedValue,
            },
          ],
        },
      }));
    }
  };

  let DrugFields = {
    Color: undefined,
    YourAnswer: undefined,
    CorrectAnswer: undefined,
    Points: undefined,
  };
  let StrengthFields = {
    Color: undefined,
    YourStrength: undefined,
    YourStrengthUnits: undefined,
    CorrectStrength: undefined,
    StrengthUnits: undefined,
    Points: undefined,
  };
  let DispFields = {
    Color: undefined,
    YourAnswer: undefined,
    CorrectAnswer: undefined,
    Points: undefined,
  };
  let RepeatFields = {
    Color: undefined,
    YourAnswer: undefined,
    CorrectAnswer: undefined,
    Points: undefined,
  };
  let SigFields = {
    Color: undefined,
    YourAnswer: undefined,
    CorrectAnswer: undefined,
    Points: undefined,
    ComponentResults: undefined,
    Feedback: undefined,
  };
  //let explanation = "";
  //let totalPoints = 0;

  if (reviewMode) {
    //explanation = reviewData.Explanation;
    DrugFields = reviewData.Drug;
    StrengthFields = reviewData.Strength;
    DispFields = reviewData.Disp;
    RepeatFields = reviewData.Repeat;
    SigFields = reviewData.Sig;
    //totalPoints = reviewData.TotalPoints;
  } else {
    if (currentPerscription == undefined) {
      return <>...Loading</>;
    }
  }

  return (
    <div className="leftReset">
      <div className="flex flex-col items-center">
        <div className="bg-white w-[100%] text-center">
          <span className={`font-bold ${textSize?.right}`}>
            Write a perscription for the most appropriate drug for this patient,
            using the generic name of the drug.
          </span>
        </div>
        <div className="flex flex-col items-center border border-2 border-black w-[100%]">
          <div
            className={`flex flex-col items-center font-bold ${textSize?.right}`}
          >
            <span>DOCTOR DENTIST</span>
            <span>100 ANYWHERE STREET</span>
            <span>ANYWHERE CITY, CANADA</span>
            <span>TELEPHONE XXX-XXX-XXX</span>
          </div>
          <div className={`flex w-[100%] justify-between ${textSize?.right}`}>
            <div className="pl-2 flex space-x-5">
              <span className="font-bold w-[150px]">Patient Name:</span>
              <span>John Doe</span>
            </div>
            <div className="mr-5">
              <span className="font-bold">Date:</span>
              <span> TODAY</span>
            </div>
          </div>
          <div className={`flex w-[100%] justify-between ${textSize?.right}`}>
            <div className="pl-2 flex space-x-5">
              <span className="font-bold w-[150px]">Address:</span>
              <span>123 Main Street, Ottawa, ON</span>
            </div>
          </div>
          <div className="pl-4 mt-[30px] flex items-start w-[100%]">
            <span className="text-5xl">℞</span>
          </div>
          <div className="pl-4 mt-[30px] flex space-x-5 items-start w-[100%]">
            <div className="flex space-x-5 items-center">
              <span className={`font-bold ${textSize?.right ?? "text-xl"}`}>
                Drug:
              </span>
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <input
                  type="text"
                  id="drug"
                  name="drug"
                  className="h-[50px] border border-gray-400 rounded px-2 py-1 w-full"
                  value={currentPerscription?.drug}
                  onChange={(e) => handleInputChange("drug", e.target.value, e)}
                />
              )}
              {/* Review mode Drug fields */}
              {(() => {
                if (!reviewMode) return null;

                const colorObject = getReviewColors(DrugFields.Color);

                return (
                  <div className="rounded-3px p-3 shadow-custom">
                    <div
                      className={`${colorObject.colorString} ${colorObject.textString}`}
                    >
                      <span className="font-bold">Your Answer: </span>
                      <span>{DrugFields?.YourAnswer}</span>
                    </div>
                    <div className={`${testgreenBackground} ${testgreenText}`}>
                      <span className="font-bold">Correct Answer: </span>
                      <span>{DrugFields?.CorrectAnswer}</span>
                    </div>
                    <div
                      className={`${
                        DrugFields.Points == 0
                          ? testRedBackground
                          : testgreenBackground
                      } ${
                        DrugFields.Points == 0 ? testRedText : testgreenText
                      }`}
                    >
                      <span className="font-bold">Percentage of Total: </span>
                      <span>{(DrugFields.Points / 4) * 100}%</span>
                    </div>
                  </div>
                );
              })()}
            </div>
            <div className="flex space-x-5 items-center">
              <span className={`font-bold ${textSize?.right ?? "text-xl"}`}>
                Strength:
              </span>
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <input
                  type="text"
                  id="strength"
                  name="strength"
                  className="h-[50px] border border-gray-400 rounded px-2 py-1 w-full"
                  value={currentPerscription?.strength}
                  onChange={(e) =>
                    handleInputChange("strength", e.target.value, e)
                  }
                />
              )}{" "}
              {/* Review mode display fields */}
              {(() => {
                if (!reviewMode) return null;

                const colorObject = getReviewColors(StrengthFields.Color);

                return (
                  <div className="rounded-3px p-3 shadow-custom">
                    <div
                      className={`${colorObject.colorString} ${colorObject.textString}`}
                    >
                      <span className="font-bold">Your Answer: </span>
                      <span>
                        {StrengthFields?.YourStrength}{" "}
                        {StrengthFields?.YourStrengthUnits}
                      </span>
                    </div>
                    <div className={`${testgreenBackground} ${testgreenText}`}>
                      <span className="font-bold">Correct Answer: </span>
                      <span>
                        {StrengthFields?.CorrectStrength}{" "}
                        {StrengthFields?.StrengthUnits}
                      </span>
                    </div>
                    <div
                      className={`${
                        StrengthFields.Points == 0
                          ? testRedBackground
                          : testgreenBackground
                      } ${
                        StrengthFields.Points == 0 ? testRedText : testgreenText
                      }`}
                    >
                      <span className="font-bold">Percentage of Total:</span>
                      <span>{(StrengthFields.Points / 4) * 100}%</span>
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
          <div className="pl-4 mt-[30px] flex space-x-5 items-start w-[100%]">
            <div className="flex space-x-5 items-center">
              <span className={`font-bold ${textSize?.right ?? "text-xl"}`}>
                Disp.:
              </span>
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <input
                  type="number"
                  id="disp"
                  name="disp"
                  className="h-[50px] border border-gray-400 rounded px-2 py-1 w-[80px]"
                  value={currentPerscription?.disp}
                  onChange={(e) => handleInputChange("disp", e.target.value, e)}
                />
              )}
              {/* Review mode display fields */}
              {(() => {
                if (!reviewMode) return null;

                const colorObject = getReviewColors(DispFields.Color);

                return (
                  <div className="rounded-3px p-3 shadow-custom">
                    <div
                      className={`${colorObject.colorString} ${colorObject.textString}`}
                    >
                      <span className="font-bold">Your Answer: </span>
                      <span>{DispFields?.YourAnswer} </span>
                    </div>
                    <div className={`${testgreenBackground} ${testgreenText}`}>
                      <span className="font-bold">Correct Answer: </span>
                      <span>{DispFields?.CorrectAnswer} </span>
                    </div>
                    <div
                      className={`${
                        DispFields.Points == 0
                          ? testRedBackground
                          : testgreenBackground
                      } ${
                        DispFields.Points == 0 ? testRedText : testgreenText
                      }`}
                    >
                      <span className="font-bold">Percentage of Total: </span>
                      <span>{(DispFields.Points / 4) * 100}%</span>
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
          <div className="pl-4 pr-10 mt-[30px] flex items-start w-[100%]">
            <div className="flex flex-col items-start w-[100%] space-y-5">
              <span className={`font-bold ${textSize?.right ?? "text-xl"}`}>
                Sig.:
              </span>
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <input
                  type="text"
                  id="sig"
                  name="sig"
                  className="border border-gray-400 rounded py-1 w-[100%] h-[120px]"
                  value={currentPerscription?.sig}
                  onChange={(e) => handleInputChange("sig", e.target.value, e)}
                />
              )}
              {/* Review mode signature fields fields */}
              {(() => {
                if (!reviewMode) return null;

                //const colorObject = getReviewColors("gray");

                return (
                  <div className="flex">
                    <div className="rounded-3px p-3 shadow-custom">
                      <div
                        className={`${testgrayBackground} ${testgrayText} flex flex-col`}
                      >
                        <span className="font-bold">Component Results </span>
                        {Object.entries(SigFields?.ComponentResults).map(
                          ([key, value]) => (
                            <div
                              key={key}
                              className={`${
                                value == false
                                  ? testRedBackground
                                  : testgreenBackground
                              } ${
                                value == false ? testRedText : testgreenText
                              }`}
                            >
                              <span className="font-bold">
                                {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
                              </span>
                              <span>{value ? "Correct" : "Incorrect"}</span>
                            </div>
                          )
                        )}
                      </div>
                      <div
                        className={`${
                          SigFields.Points == 0
                            ? testRedBackground
                            : testgreenBackground
                        } ${
                          SigFields.Points == 0 ? testRedText : testgreenText
                        }`}
                      >
                        <span className="font-bold">Percentage of Total: </span>
                        <span>{(SigFields.Points / 4) * 100}%</span>
                      </div>
                    </div>
                    <div className="rounded-3px p-3 shadow-custom">
                      <div
                        className={`${testgrayBackground} ${testgrayText} flex flex-col`}
                      >
                        <span className="font-bold">Feedback </span>
                        {SigFields?.Feedback.map((feedback) => (
                          <div
                            key={feedback}
                            className={`${
                              feedback.includes("Incorrect")
                                ? testRedBackground
                                : testgreenBackground
                            } ${
                              feedback.includes("Incorrect")
                                ? testRedText
                                : testgreenText
                            }`}
                          >
                            <span className="font-bold">{feedback}</span>
                          </div>
                        ))}
                        {SigFields?.Feedback?.length == 0 && (
                          <div
                            className={`${testgreenText} ${testgreenBackground}`}
                          >
                            <span className="font-bold">
                              You have entered everything correctly
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
          <div className="pl-4 pb-10 mt-[30px] flex space-x-5 items-start w-[100%]">
            <div className="flex space-x-5 items-center">
              <span className={`font-bold ${textSize?.right ?? "text-xl"}`}>
                Repeat
              </span>
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <input
                  type="number"
                  id="repeat"
                  name="repeat"
                  className="h-[50px] border border-gray-400 rounded px-2 py-1 w-[80px]"
                  value={currentPerscription?.repeat}
                  onChange={(e) =>
                    handleInputChange("repeat", e.target.value, e)
                  }
                />
              )}
              {!reviewMode && !ShowAnswerMode && !ShowMyAnswerMode && (
                <span className={`${textSize?.right ?? "text-xl"}`}>times</span>
              )}
              {/* Review mode repeat fields */}
              {(() => {
                if (!reviewMode) return null;

                const colorObject = getReviewColors(RepeatFields.Color);

                return (
                  <div className="rounded-3px p-3 shadow-custom">
                    <div
                      className={`${colorObject.colorString} ${colorObject.textString}`}
                    >
                      <span className="font-bold">Your Answer: </span>
                      <span>{RepeatFields?.YourAnswer} </span>
                    </div>
                    <div className={`${testgreenBackground} ${testgreenText}`}>
                      <span className="font-bold">Correct Answer: </span>
                      <span>{RepeatFields?.CorrectAnswer} </span>
                    </div>
                    <div
                      className={`${
                        RepeatFields.Points == 0
                          ? testRedBackground
                          : testgreenBackground
                      } ${
                        RepeatFields.Points == 0 ? testRedText : testgreenText
                      }`}
                    >
                      <span className="font-bold">Percentage of Total: </span>
                      <span>{(RepeatFields.Points / 4) * 100}%</span>
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerscriptionAnswerBlock;

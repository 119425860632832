"use client";

import { useState } from "react";
import { ArrowDownUp, SquareX, Flag, NotebookPen } from "lucide-react";

export default function QuestionNavigator({
  onClose,
  questionsIdData,
  examinationState,
  NavigateToQuestion,
  showNotes,
}) {
  const [sortType, setSortType] = useState("");

  const closeNavigator = () => {
    onClose();
  };

  const navigateToQuestion = (question: number) => {
    NavigateToQuestion(question);
  };

  let mutableQuestionIdData = [...questionsIdData];

  switch (sortType) {
    case "QuestionNumber-ASC":
      mutableQuestionIdData = [...questionsIdData];
      break;
    case "QuestionNumber-DESC":
      mutableQuestionIdData = mutableQuestionIdData.reverse();
      break;
    case "Status-ASC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.answersById[a] < examinationState.answersById[b])
          return -1;
        if (examinationState.answersById[a] > examinationState.answersById[b])
          return 1;
        return 0;
      });
      break;
    case "Status-DESC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.answersById[a] > examinationState.answersById[b])
          return -1;
        if (examinationState.answersById[a] < examinationState.answersById[b])
          return 1;
        return 0;
      });
      break;
    case "MarkedForReview-ASC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.reviewById[a] < examinationState.reviewById[b])
          return -1;
        if (examinationState.reviewById[a] > examinationState.reviewById[b])
          return 1;
        return 0;
      }); // Sort by MarkedForReview status (true first in ascending order)
      break;
    case "MarkedForReview-DESC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.reviewById[a] > examinationState.reviewById[b])
          return -1;
        if (examinationState.reviewById[a] < examinationState.reviewById[b])
          return 1;
        return 0;
      }); // Sort by MarkedForReview status (false first in descending order)
      break;
    case "Notes-ASC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.notesById[a] < examinationState.notesById[b])
          return -1;
        if (examinationState.notesById[a] > examinationState.notesById[b])
          return 1;
        return 0;
      }); // Sort by Notes alphabetically (ascending)
      break;
    case "Notes-DESC":
      mutableQuestionIdData.sort((a, b) => {
        if (examinationState.notesById[a] > examinationState.notesById[b])
          return -1;
        if (examinationState.notesById[a] < examinationState.notesById[b])
          return 1;
        return 0;
      }); // Sort by Notes alphabetically (descending)
      break;
    default:
      //console.warn("Invalid sorting option");
  }

  return (
    <div className="flex flex-col h-[calc(100dvh)] font-sans text-sm w-full">
      <div className="flex justify-between items-center text-lg font-bold p-1 pl-5 pr-2.5">
        <span>Navigator</span>
        <SquareX className="cursor-pointer" onClick={closeNavigator} />
      </div>

      <div className="overflow-x-auto">
        <table className="w-[calc(100%-40px)] mx-auto border-collapse ">
          <thead>
            <tr className="bg-[#5E5E5E] text-white">
              <th className="font-normal p-0.5 text-center border border-gray-300 relative">
                <div className="flex justify-center items-center">
                  <span>Question Number</span>
                  <ArrowDownUp
                    className="w-3 h-3 ml-auto right-1 cursor-pointer"
                    onClick={() => {
                      sortType == "QuestionNumber-ASC"
                        ? setSortType("QuestionNumber-DESC")
                        : setSortType("QuestionNumber-ASC");
                    }}
                  />
                </div>
              </th>
              <th className="font-normal p-0.5 text-center border border-gray-300 relative">
                <div className="flex justify-center items-center">
                  <span>Status</span>
                  <ArrowDownUp
                    className="w-3 h-3 ml-auto right-1 cursor-pointer"
                    onClick={() => {
                      sortType == "Status-ASC"
                        ? setSortType("Status-DESC")
                        : setSortType("Status-ASC");
                    }}
                  />
                </div>
              </th>
              <th className="font-normal p-0.5 text-center border border-gray-300 relative">
                <div className="flex justify-center items-center">
                  <span>Marked for Review</span>
                  <ArrowDownUp
                    className="w-3 h-3 ml-auto right-1 cursor-pointer"
                    onClick={() => {
                      sortType == "MarkedForReview-ASC"
                        ? setSortType("MarkedForReview-DESC")
                        : setSortType("MarkedForReview-ASC");
                    }}
                  />
                </div>
              </th>
              <th className="font-normal p-0.5 text-center border border-gray-300 relative">
                <div className="flex justify-center items-center">
                  <span>Notes</span>
                  <ArrowDownUp
                    className="w-3 h-3 ml-auto right-1 cursor-pointer"
                    onClick={() => {
                      sortType == "Notes-ASC"
                        ? setSortType("Notes-DESC")
                        : setSortType("Notes-ASC");
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {mutableQuestionIdData.map((question, index) => (
              <tr key={index} className="even:bg-gray-100 odd:bg-gray-300">
                <td className="p-0.5 text-left border border-gray-300">
                  <button
                    className="bg-transparent border-none text-black underline cursor-pointer hover:text-blue-800"
                    onClick={() => navigateToQuestion(index)}
                  >
                    Question {questionsIdData.indexOf(question) + 1}
                  </button>
                </td>
                <td className="p-0.5 text-left border border-gray-300">
                  {examinationState.answersById[question]?.length
                    ? "Answers present"
                    : "Incomplete"}
                </td>
                <td className="p-2 text-center border border-gray-300">
                  {examinationState.reviewById[question] && (
                    <Flag
                      className="inline-block w-4 h-4 align-middle"
                      aria-label="Marked for Review"
                    />
                  )}
                </td>
                <td className="p-2 text-center border border-gray-300">
                  {examinationState.notesById[question] && (
                    <button
                      className="bg-transparent border-none text-black underline cursor-pointer hover:text-blue-800"
                      onClick={() => {
                        navigateToQuestion(index);
                        showNotes();
                        closeNavigator();
                      }}
                    >
                      <NotebookPen
                        className="inline-block w-4 h-4 align-middle"
                        aria-label="Notes"
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="p-1 text-left mt-auto">
        <p className="m-0">
          {questionsIdData.length} Questions,{" "}
          {questionsIdData.length -
            Object.keys(examinationState.answerChoices).length}{" "}
          Unseen
        </p>
      </div>

      <div className="p-1 px-2.5 border-t border-gray-300 text-left bg-secondaryLight sticky bottom-0 w-full text-sm">
        <button
          className="py-0.5 px-2.5 bg-[#5E5E5E] text-white border-none cursor-pointer text-lg hover:bg-[#355e5f]"
          onClick={closeNavigator}
        >
          Close
        </button>
      </div>
    </div>
  );
}

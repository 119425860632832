import { useEffect, useRef, useState } from "react";
import { formatTimeLeft } from "../../helpers/formatters";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestExtraSeconds,
  getTestOutOfTime,
  getTestStartSecondsSelector,
  getTestStartSelector,
  setTestOutOfTime,
} from "../../store/test.slice";

function ExamTimer({ isLoading, currentQuestionIndex, numberOfQuestions }) {
  const dispatch = useDispatch();
  const loadingStartTime = useRef(null);
  //Time logic start
  const testStartDate = useSelector(getTestStartSelector);
  const testStartSeconds = useSelector(getTestStartSecondsSelector);
  const testExtraSeconds = useSelector(getTestExtraSeconds);
  const testOutOfTime = useSelector(getTestOutOfTime);
  const [additionalPauseTime, setAdditionalPauseTime] = useState(0);

  // Calculate target time including pause time
  const targetTime = (() => {
    let base;
    if (testStartSeconds === undefined) {
      base =
        testStartDate +
        numberOfQuestions * 2 * 60 * 1000 +
        testExtraSeconds * 1000;
    } else {
      const initialTimestamp = new Date(testStartDate);
      base = new Date(
        initialTimestamp.getTime() +
          testStartSeconds * 1000 +
          testExtraSeconds * 1000
      ).getTime();
    }
    return base + additionalPauseTime;
  })();

  const [timeLeft, setTimeLeft] = useState({
    timeRemaining: targetTime - Date.now(),
    timeSpentOnQuestionsById: {},
  });

  // Handle loading state changes
  useEffect(() => {
    if (isLoading) {
      loadingStartTime.current = Date.now();
    } else if (!isLoading && loadingStartTime.current) {
      const pauseDuration = Date.now() - loadingStartTime.current;
      setAdditionalPauseTime((prev) => prev + pauseDuration);
      loadingStartTime.current = null;
    }
  }, [isLoading]);

  useEffect(() => {
    const timer = setInterval(() => {
      const timeRemaining = targetTime - Date.now();
      if (!isLoading) {
        // Update timeSpentOnQuestionsById
        setTimeLeft((prevState) => {
          const timeSpentOnQuestionsById = {
            ...prevState.timeSpentOnQuestionsById,
          };
          const previousTimeSpent =
            timeSpentOnQuestionsById[currentQuestionIndex] || 0;

          // Increment time spent by 1 second
          timeSpentOnQuestionsById[currentQuestionIndex] =
            previousTimeSpent + 1;

          return {
            ...prevState,
            timeRemaining: timeRemaining,
            timeSpentOnQuestionsById: timeSpentOnQuestionsById,
          };
        });
      }
      if (timeRemaining <= 0 && testOutOfTime == false) {
        dispatch(
          setTestOutOfTime({
            testOutOfTime: true,
            whenTestRanOutOfTime: Date.now(),
          })
        );
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime, isLoading, currentQuestionIndex, testOutOfTime, dispatch]);

  //Timer logic end
  return (
    <div className="text-right sm:text-left">
      Time Remaining in Section: {formatTimeLeft(timeLeft.timeRemaining)}
    </div>
  );
}

export default ExamTimer;

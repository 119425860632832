import { apiSlice } from "../apiSlice";

const testExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startTest: builder.mutation({
      query: ({ testStartData }) => ({
        url: `starttest.webapi/`,
        method: "POST",
        body: { ...testStartData },
      }),
    }),
    startIncompleteTest: builder.mutation({
      query: ({ testStartData }) => ({
        url: `startincompletetest.webapi/`,
        method: "POST",
        body: { ...testStartData },
      }),
    }),
    updateProgress: builder.mutation({
      query: ({ testData }) => ({
        url: `getuserdata.webapi/`,
        method: "POST",
        body: { ...testData },
      }),
    }),
    gardeTest: builder.mutation({
      query: ({ testData }) => ({
        url: `grading.webapi/`,
        method: "POST",
        body: { ...testData },
      }),
    }),
    gettests: builder.query({
      query: () => ({
        url: `gettests.webapi/`,
      }),
    }),
    updateReviewLater: builder.mutation({
      query: ({ data }) => ({
        url: `updatereviewlater.webapi/`,
        method: "POST",
        body: { ...data },
      }),
    }),
    updateHighyield: builder.mutation({
      query: ({ data }) => ({
        url: `updatehighyield.webapi/`,
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useStartTestMutation,
  useUpdateProgressMutation,
  useStartIncompleteTestMutation,
  useGardeTestMutation,
  useGettestsQuery,
  useUpdateReviewLaterMutation,
  useUpdateHighyieldMutation
} = testExtendedSlice;

import Cookies from "universal-cookie";
/*
export function authenticateUser(token) {
  const jwt = token || getAuthData();
  if (!jwt) return [false];
  try {
    const decoded = jwtDecode(jwt);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);//TODO: implement a mechanic for deleting expired tokens
    return [
      decoded.exp > nowInSec,
      decoded,
      jwt
    ];
  } catch (unusedERROR) {
    console.log('Invalid token, login to continue');
    return [false];
  }
}*/

/*export function setAuthData(value:string, name = 'authCode') {
  localStorage.setItem(name, value);
  document.cookie = `${name}=${value};domain=localhost;path=/;SameSite=Lax`;//TODO: Throw this into an env at some point
  return document.cookie;
}*/

function setCookie(
  name,
  value,
  options = {
    expires: undefined,
    path: undefined,
    domain: undefined,
    secure: undefined,
    sameSite: undefined,
  }
) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options.expires) {
    const date = new Date(options.expires);
    cookieString += `; expires=${date.toUTCString()}`;
  }

  if (options.path) {
    cookieString += `; path=${options.path}`;
  }

  if (options.domain) {
    cookieString += `; domain=${options.domain}`;
  }

  if (options.secure) {
    cookieString += `; Secure`;
  }

  if (options.sameSite) {
    cookieString += `; SameSite=${options.sameSite}`;
  }

  document.cookie = cookieString;
}

export function setAuthData(value, name = "authCode") {
  localStorage.setItem(name, value);
  setCookie(name, value, {
    expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // Expires in 7 days
    path: "/",
    secure: false, // Set to true if served over HTTPS
    sameSite: "Lax", // or 'None' for cross-site usage
    domain:import.meta.env.VITE_DEBUG ? "localhost" : "www.oscedental.com",
  });

  //console.log(`Cookie set: ${name}=${value}`);
}

export function getAuthData(name = "authCode") {
  // Check localStorage first
  const localStorageValue = localStorage.getItem(name);
  if (localStorageValue) {
    return localStorageValue;
  }

  // If not in localStorage, check cookies
  const cookies = new Cookies();
  const cookieValue = cookies.get(name);

  if (cookieValue) {
    return cookieValue;
  }

  return null; // Return null if not found
}

export function invalidateToken(name = "authCode") {
  localStorage.setItem(name, undefined);
}

import { Check, X } from "lucide-react";
import {
  AnsweringResult,
  testgrayBackground,
  testgrayText,
  testgreenBackground,
  testgreenText,
  testRedBackground,
  testRedText,
} from "../../constants/test";
import { textSizeIndexes } from "../../constants/textSize";

function ReviewedAnswersBlock({ sortedAnswers, textSize }) {
  return (
    <>
      {sortedAnswers.map((answer, index) => {
        let colorString = "bg-purple-200";
        let textString = "";
        switch (answer.Color) {
          case "red":
            colorString = testRedBackground;
            textString = testRedText;
            break;
          case "gray":
            colorString = testgrayBackground;
            textString = testgrayText;
            break;
          case "green":
            colorString = testgreenBackground;
            textString = testgreenText;
            break;
        }
        const baseSize = textSizeIndexes[textSize];
        const lgSize =
          textSizeIndexes[Math.min(textSize + 1, textSizeIndexes.length - 1)];

        return (
          <div
            className={`rounded-3px ${colorString} px-5 py-3 shadow-custom ${baseSize}`}
            key={`${answer.Answer}${index}`}
          >
            <div className="flex flex-col space-y-5">
              <div className="flex space-x-3">
                <span className={`font-bold ${lgSize} ${textString} flex`}>
                  {answer.AnswerClause}{" "}
                </span>
                {AnsweringResult.Correct == answer.AnswerClause ||
                  (AnsweringResult.Unselected == answer.AnswerClause && (
                    <Check className="text-[#175317]" />
                  ))}
                {AnsweringResult.Incorrect == answer.AnswerClause && (
                  <X className="text-[#411414]" />
                )}
              </div>
              <li>
                <span className={`font-bold ${textString}`}>
                  {answer.Answer}
                </span>
              </li>
              <span className={`${textString}`}>
                <span className="font-bold">Reason:</span> {answer.Reason}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ReviewedAnswersBlock;

import { RouterProvider } from 'react-router-dom';
import router from './index';

function Routers() {
  return (
    <RouterProvider router={router}/>
  );
}

export default Routers;

const UniChart = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 space-y-8">
      <h1 className="text-center font-semibold text-lg">
        FDI / UNIVERSAL NUMBERING SYSTEM
      </h1>

      {/* Permanent Dentition */}
      <div className="space-y-2">
        <h2 className="text-center font-medium mb-4">PERMANENT DENTITION</h2>
        <table className="w-full text-sm">
          <tbody>
            <tr>
              <td className="w-12 font-medium text-right pr-2">FDI</td>
              {[
                "1.8",
                "1.7",
                "1.6",
                "1.5",
                "1.4",
                "1.3",
                "1.2",
                "1.1",
                "2.1",
                "2.2",
                "2.3",
                "2.4",
                "2.5",
                "2.6",
                "2.7",
                "2.8",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 7 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">FDI</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">Universal</td>
              {[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 7 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">Universal</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">Universal</td>
              {[
                "32",
                "31",
                "30",
                "29",
                "28",
                "27",
                "26",
                "25",
                "24",
                "23",
                "22",
                "21",
                "20",
                "19",
                "18",
                "17",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 7 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">Universal</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">FDI</td>
              {[
                "4.8",
                "4.7",
                "4.6",
                "4.5",
                "4.4",
                "4.3",
                "4.2",
                "4.1",
                "3.1",
                "3.2",
                "3.3",
                "3.4",
                "3.5",
                "3.6",
                "3.7",
                "3.8",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 7 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">FDI</td>
            </tr>
            <tr>
              <td className="w-12"></td>
              <td colSpan={8} className="text-center font-medium">
                RIGHT
              </td>
              <td colSpan={8} className="text-center font-medium">
                LEFT
              </td>
              <td className="w-12"></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Primary Dentition */}
      <div className="space-y-2">
        <h2 className="text-center font-medium mb-4">PRIMARY DENTITION</h2>
        <table className="w-full text-sm">
          <tbody>
            <tr>
              <td className="w-12 font-medium text-right pr-2">FDI</td>
              {[
                "5.5",
                "5.4",
                "5.3",
                "5.2",
                "5.1",
                "6.1",
                "6.2",
                "6.3",
                "6.4",
                "6.5",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 4 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">FDI</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">Universal</td>
              {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].map(
                (letter, index) => (
                  <td
                    key={letter}
                    className={`text-center border-b ${
                      index === 4 ? "border-r" : ""
                    }`}
                  >
                    {letter}
                  </td>
                )
              )}
              <td className="w-12 font-medium text-left pl-2">Universal</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">Universal</td>
              {["T", "S", "R", "Q", "P", "O", "N", "M", "L", "K"].map(
                (letter, index) => (
                  <td
                    key={letter}
                    className={`text-center border-b ${
                      index === 4 ? "border-r" : ""
                    }`}
                  >
                    {letter}
                  </td>
                )
              )}
              <td className="w-12 font-medium text-left pl-2">Universal</td>
            </tr>
            <tr>
              <td className="w-12 font-medium text-right pr-2">FDI</td>
              {[
                "8.5",
                "8.4",
                "8.3",
                "8.2",
                "8.1",
                "7.1",
                "7.2",
                "7.3",
                "7.4",
                "7.5",
              ].map((num, index) => (
                <td
                  key={num}
                  className={`text-center border-b ${
                    index === 4 ? "border-r" : ""
                  }`}
                >
                  {num}
                </td>
              ))}
              <td className="w-12 font-medium text-left pl-2">FDI</td>
            </tr>
            <tr>
              <td className="w-12"></td>
              <td colSpan={5} className="text-center font-medium">
                RIGHT
              </td>
              <td colSpan={5} className="text-center font-medium">
                LEFT
              </td>
              <td className="w-12"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UniChart;
import { Info } from "lucide-react";
import { useState } from "react";

function ToolTip({ tip, position = "right",width }) {
  const [isHovered, setIsHovered] = useState(false);
  const positionClasses = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
  };

  return (
    <div className="relative inline-block group">
      <Info
        className="h-5 w-5 text-primaryText"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <div
        className={`absolute ${
          positionClasses[position]
        } bg-secondaryDark text-contrastText text-sm rounded py-1 px-2 opacity-${
          isHovered ? 100 : 0
        } transition-opacity duration-300 z-10 ${width?width:""}`}
      >
        {tip}
      </div>
    </div>
  );
}

export default ToolTip;

import { useDispatch, useSelector } from "react-redux";
import {
  getReviewHighYield,
  getUserData,
  getUserDataTimeZone,
  setExamHistory,
  setReviewHighYield,
} from "../store/login.slice";
import { useEffect, useState } from "react";
import { formatDateTime, limitDigits } from "../helpers/formatters";
import {
  useGardeTestMutation,
  useGettestsQuery,
  useUpdateHighyieldMutation,
  useUpdateReviewLaterMutation,
} from "../api/testSlice.ts/testSlice";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";
import { toast } from "react-toastify";
import { ArrowLeft, Eye, User, Users } from "lucide-react";
import ToolTip from "../components/tooltip/tooltip";
import CheckBox from "../components/checkbox/checkbox";
import { showSidebar, hideSidebar } from "../store/login.slice";
import Modal from "../components/modal/Modal";
import TestCenterColumns from "../components/testCenterColumns/testCenterColumns";
import {
  GetUnmodifiedAnswerArray,
  getUnmodifiedHighlightedText,
  processQuestionLabel,
  processQuestionText,
} from "../helpers/testHelpers";
import {
  useGetQuestionByQuestionIdQuery,
  useGetQuestionImageByIdQuery,
} from "../api/questionsSlice/questionsSlice";
import { getExamToReview, setReviewedExam } from "../store/test.slice";
import { textSizeIndexes } from "../constants/textSize";
import { AnsweringResult } from "../constants/test";
import ReviewedAnswersBlock from "../components/reviewedAnswersBlock/reviewedanswersblock";
import YourAnswersBlock from "../components/yourAnswersBlock/youranswersblock";
import { useParams } from "react-router-dom";
import PerscriptionAnswerBlock from "../components/PerscriptionAnswerBlock/perscriptionAnswerBlock";

function CompleteTests() {
  //
  //Selectors
  //
  const userData = useSelector(getUserData);
  const timeZone = useSelector(getUserDataTimeZone);
  const reviewedTest = useSelector(getExamToReview);
  const reviewHighYield = useSelector(getReviewHighYield);

  //
  //local state
  //
  const [expanded, setExpanded] = useState([]);
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  const [reviewedQuestion, setReviewedQuestion] = useState({
    reviewing: false,
    questionId: undefined,
    answers: undefined,
    choices: undefined,
    reviewData: undefined,
    type: undefined,
  });
  const [sortingMode, setSortingMode] = useState({
    start: "",
    last: "DESC",
    finish: "",
  });
  const [textSize, setTextSize] = useState(2);

  //
  //Hooks
  //
  const dispatch = useDispatch();
  useTokenSpoilCheck(errorStatusCode);

  //
  //Queries
  //
  const { data, isLoading, isFetching } = useGetQuestionByQuestionIdQuery(
    {
      question_id: reviewedQuestion.questionId,
      choices: reviewedQuestion.choices,
    },
    {
      skip: !reviewedQuestion?.questionId, // Skip the query if question_id is null or undefined
    }
  );

  const {
    data: currentQuestionImage,
    isLoading: questionImageFirstLoad,
    isFetching: questionImageLoading,
  } = useGetQuestionImageByIdQuery(
    {
      question_id: reviewedQuestion.questionId,
    },
    {
      skip: !reviewedQuestion?.questionId, // Skip the query if question_id is null or undefined
    }
  );

  const imageSource =
    currentQuestionImage && currentQuestionImage.type.startsWith("image/")
      ? URL.createObjectURL(currentQuestionImage)
      : null;

  const { data: tests, refetch } = useGettestsQuery({});

  const [gardeTest] = useGardeTestMutation();
  const [updateReviewLater] = useUpdateReviewLaterMutation();
  const [updateHighyield] = useUpdateHighyieldMutation();
  //
  //UseEffect hooks
  //
  useEffect(() => {
    refetch();
  }, [refetch]);
  useEffect(() => {
    if (tests?.CompletedTests && tests?.IncompleteTests) {
      const CompletedTests = tests?.CompletedTests;
      const IncompleteTests = tests.IncompleteTests;
      dispatch(setExamHistory({ CompletedTests, IncompleteTests }));
    }
  }, [tests]);

  //
  //Copy protection
  //
  useEffect(() => {
    // Disable right-click context menu
    const disableContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", disableContextMenu);

    // Disable copy and cut keyboard shortcuts
    const disableCopyCut = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "x")
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", disableCopyCut);

    // Disable the copy event
    const disableCopyEvent = (event) => {
      event.preventDefault();
    };
    document.addEventListener("copy", disableCopyEvent);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableCopyCut);
      document.removeEventListener("copy", disableCopyEvent);
    };
  }, []);

  //
  //Util funcs
  //
  const fetchTestReview = async (guid, prevData) => {
    let result;
    try {
      result = await gardeTest({ testData: { guid } }).unwrap();
      dispatch(
        setReviewedExam({
          examToReview: { test: result, guid: guid, prevData },
        })
      );
      dispatch(hideSidebar());
    } catch (error) {
      toast(error.data.message);
      setErrorStatusCode(error?.status);
      console.log(error);
    }
  };

  //
  //Overrides
  //
  const { guid } = useParams(); // Get questionId from the URL

  useEffect(() => {
    if (guid) {
      fetchTestReview(guid, []);
    }
  }, [guid]); // Dependency array ensures this effect runs when 'guid' changes

  //Data
  let questionType = "";
  if (reviewedQuestion?.type != undefined) {
    questionType = reviewedQuestion?.type;
  }

  if (data?.GetQuestion?.question_type != undefined) {
    questionType = data?.GetQuestion?.question_type;
  }

  //We have no exam being reviewed at the moment just show the list
  if (reviewedTest == undefined) {
    let Data = [];
    if (userData.CompletedTests) {
      Data = [...userData.CompletedTests];
    }

    const sortedData = Data.sort((a, b) => {
      // If sortingMode.start is not empty, sort by TimeTestStarted
      if (sortingMode.start) {
        const startA = new Date(a.TimeTestStarted);
        const startB = new Date(b.TimeTestStarted);
        if (sortingMode.start === "ASC") {
          // @ts-expect-error. Dates work with subtraction
          return startA - startB;
        } else if (sortingMode.start === "DESC") {
          // @ts-expect-error. Dates work with subtraction
          return startB - startA;
        }
      }

      // If sortingMode.last is not empty, sort by LastUsed
      if (sortingMode.finish) {
        const finishA = new Date(a.TimeTestFinished);
        const finishB = new Date(b.TimeTestFinished);
        if (sortingMode.finish === "ASC") {
          // @ts-expect-error. Dates work with subtraction
          return finishA - finishB;
        } else if (sortingMode.finish === "DESC") {
          // @ts-expect-error. Dates work with subtraction
          return finishB - finishA;
        }
      }

      if (sortingMode.last) {
        const lastA = new Date(a.LastUsed);
        const lastB = new Date(b.LastUsed);
        if (sortingMode.last === "ASC") {
          // @ts-expect-error. Dates work with subtraction
          return lastA - lastB;
        } else if (sortingMode.last === "DESC") {
          // @ts-expect-error. Dates work with subtraction
          return lastB - lastA;
        }
      }
      return 0; // Default case when no sorting mode is applied
    });

    return (
      <>
        <div className="bg-primaryBackground text-secondaryText font-normal p-4 flex justify-between w-full">
          <div>
            <span className="text-3xl">Complete Tests</span>
          </div>
        </div>

        <div className="rounded-lg bg-primaryBackground mx-5 mt-1 mb-2">
          <div className="flex space-x-5 p-4">
            <button
              className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
              onClick={() => {
                if (sortingMode.start == "") {
                  setSortingMode({ last: "", start: "ASC", finish: "" });
                } else if (sortingMode.start == "ASC") {
                  setSortingMode({ last: "", start: "DESC", finish: "" });
                } else if (sortingMode.start == "DESC") {
                  setSortingMode({ last: "", start: "", finish: "" });
                }
              }}
            >
              Sort By Start Date {sortingMode.start}
            </button>
            <button
              className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
              onClick={() => {
                if (sortingMode.last == "") {
                  setSortingMode({ start: "", last: "ASC", finish: "" });
                } else if (sortingMode.last == "ASC") {
                  setSortingMode({ start: "", last: "DESC", finish: "" });
                } else if (sortingMode.last == "DESC") {
                  setSortingMode({ start: "", last: "", finish: "" });
                }
              }}
            >
              Sort By Last Used {sortingMode.last}
            </button>
            <button
              className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
              onClick={() => {
                if (sortingMode.finish == "") {
                  setSortingMode({ start: "", last: "", finish: "ASC" });
                } else if (sortingMode.finish == "") {
                  setSortingMode({ start: "", last: "", finish: "DESC" });
                } else if (sortingMode.finish == "") {
                  setSortingMode({ start: "", last: "", finish: "" });
                }
              }}
            >
              Sort By Finished {sortingMode.last}
            </button>
          </div>
        </div>
        {sortedData.map((test) => {
          const started = new Date(test.TimeTestStarted);
          const lastUsed = new Date(test.LastUsed);
          const finished = new Date(test.TimeTestFinished);
          return (
            <div
              className="rounded-lg bg-primaryBackground mx-5 mt-1 mb-2"
              key={test.TimeTestStarted}
            >
              <div className="flex flex-row justify-between p-4">
                <div className="flex flex-col text-primaryText">
                  <div className="flex flex-row">
                    <span>Test started:</span>
                    <span>{formatDateTime(timeZone?.value, started)}</span>
                  </div>
                  <div className="flex flex-row">
                    <span>Test finished:</span>
                    <span>
                      {test?.TimeTestFinished
                        ? formatDateTime(timeZone?.value, finished)
                        : "Unknown"}
                    </span>
                  </div>
                  <div className="flex flex-row">
                    <span className="text-red-600">
                      Grade:{" "}
                      {test?.GradeForTest != undefined
                        ? limitDigits(test?.GradeForTest)
                        : "Unknown"}
                      %
                    </span>
                  </div>
                  <div className="flex flex-row">
                    <span>Last Used:</span>
                    <span>
                      {test?.LastUsed
                        ? formatDateTime(timeZone?.value, lastUsed)
                        : "Unknown"}
                    </span>
                  </div>
                  <div className="flex flex-row">
                    <span>Test ID:</span>
                    <button
                      onClick={() => {
                        setExpanded((prev) =>
                          prev.includes(test.guid)
                            ? prev.filter((id) => id !== test.guid)
                            : [...prev, test.guid]
                        );
                      }}
                    >
                      {expanded.includes(test.guid)
                        ? test?.guid || "Unknown"
                        : test?.guid?.slice(0, 7) || "Unknown"}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col">
                  <button
                    className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
                    onClick={() => {
                      fetchTestReview(test.guid, test);
                    }}
                  >
                    <span>Review Test </span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    const finished = new Date(reviewedTest?.prevData.TimeTestFinished);

    return (
      <>
        <div className="bg-primaryBackground">
          <div className="text-white font-normal p-4 flex justify-between w-full bg-primaryDark">
            <div>
              <span className="text-3xl">OSCEDENTAL</span>
            </div>
          </div>
          <div className="mx-5 mt-1 mb-2">
            <div className="flex justify-between p-4">
              <button
                className="bg-primaryDark text-white font-bold py-2 px-4 rounded-3px hover:bg-secondaryDark w flex space-x-5 shadow-custom"
                onClick={() => {
                  dispatch(showSidebar());
                  //updateCompleteInCompleteTestData();
                  refetch();
                  dispatch(setReviewedExam({ examToReview: undefined }));
                }}
              >
                <ArrowLeft />
                <span>Back</span>
              </button>
              <div className="bg-primaryDark text-contrastText px-2 space-x-3">
                {" "}
                <label htmlFor="textSize" className="font-bold">
                  Text Size
                </label>
                <select
                  name="textSize"
                  id="textSize"
                  className="h-10 bg-primaryDark"
                  value={textSizeIndexes[textSize]}
                  onChange={(event) => {
                    const sizeString: string = event.target.value;
                    const foundIndex = textSizeIndexes.findIndex(
                      (value) => value === sizeString
                    );
                    setTextSize(foundIndex);
                  }}
                >
                  <option value="text-xs">x-small</option>
                  <option value="text-sm">small</option>
                  <option value="text-base">medium</option>
                  <option value="text-lg">large</option>
                </select>
              </div>
            </div>
          </div>
          <div className="rounded-3px bg-white mx-10 mt-1 mb-2 p-5 shadow-custom">
            <div className="flex flex-col space-y-2">
              <span className="text-lg font-semibold">{`Test with ${reviewedTest?.test.Grades.length} questions`}</span>
              <span className="text-sm font-bold text-gray-500">{`Completed on ${formatDateTime(
                timeZone?.value,
                finished
              )} questions`}</span>
            </div>
          </div>
          <div className="flex ">
            <div className=" mt-1 mb-2 py-5 ml-10">
              <div className="flex flex-row space-y-2">
                <div className="rounded-3px bg-white p-7 flex flex-row space-x-5 shadow-custom">
                  <User className="w-[60px] h-[60px] text-white bg-primaryDark rounded-3px self-center" />
                  <div className="flex flex-col">
                    <span className="text-sm font-bold text-gray-500">
                      Your score
                    </span>
                    <span
                      className={`text-xl font-bold ${
                        reviewedTest?.prevData.GradeForTest <= 70
                          ? "text-[#411414]"
                          : "text-[#175317]"
                      }`}
                    >
                      {limitDigits(reviewedTest?.prevData.GradeForTest)} %
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-1 mb-2 p-5">
              <div className="flex flex-row space-y-2 shadow-custom">
                {false && (
                  <div className="rounded-3px bg-white p-7 flex flex-row space-x-5">
                    <Users className="w-[60px] h-[60px] text-white bg-primaryDark rounded-3px self-center" />
                    <div className="flex flex-col">
                      <span className="text-sm font-bold text-gray-500">
                        Average First Attempt Score
                      </span>
                      <span className="text-xl font-bold">TODO</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Multiple choice questions */}
          {reviewedTest?.test.Grades.map((question, index) => {
            //const baseSize = textSizeIndexes[textSize];
            const lgSize =
              textSizeIndexes[
                Math.min(textSize + 2, textSizeIndexes.length - 1)
              ];

            const sortedAnswers = question?.Answers?.slice().sort((a, b) => {
              const order = {
                "Correct answer selected": 0,
                "Incorrect answer selected": 1,
                "Correct answer not selected": 2,
              };
              const rankA = order[a.AnswerClause] ?? 3;
              const rankB = order[b.AnswerClause] ?? 3;
              return rankA - rankB;
            });

            return (
              <div
                className="rounded-3px bg-white mx-10 mt-1 mb-2 p-5 shadow-custom"
                key={`${question.Question}${index}`}
              >
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-row">
                    <button
                      className={`${lgSize} font-semibold flex underline text-blue-900 mr-2`}
                      onClick={() => {
                        const questionId = question.QuestionId;
                        const choicesObject = {
                          [questionId]: [...question.YourAnswersChoices],
                        };
                        setReviewedQuestion({
                          reviewing: true,
                          questionId: questionId,
                          answers: question?.YourAnswersSerials,
                          choices: choicesObject,
                          reviewData: undefined,
                          type: undefined,
                        });
                      }}
                    >
                      <span className="flex">
                        <Eye className="mr-2 mt-0.5" /> Question {index + 1}
                      </span>
                    </button>
                    <span className="font-semibold">
                      -{" "}
                      <span
                        className={
                          question.Points * 100 < 70
                            ? "text-[#411414]"
                            : "text-[#175317]"
                        }
                      >
                        {limitDigits(question.Points * 100)}%
                      </span>{" "}
                      - {question.Question}
                    </span>
                  </div>

                  <div className="rounded-3px bg-gray-300 p-5 space-y-2 shadow-inset-custom">
                    <YourAnswersBlock
                      question={question}
                      showDisclaimer={
                        question.Points == 0 &&
                        sortedAnswers.some(
                          (item) =>
                            item.AnswerClause === AnsweringResult.Correct
                        )
                      }
                    />
                    <ReviewedAnswersBlock
                      sortedAnswers={sortedAnswers}
                      textSize={textSize}
                    />
                  </div>
                </div>
                <div className="flex space-x-3 mt-5 justify-end">
                  <div className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded-3px w flex space-x-5">
                    <CheckBox
                      text={undefined}
                      checked={reviewHighYield?.HighYieldQuestions.includes(
                        question.QuestionId
                      )}
                      setChecked={async () => {
                        const result = await updateHighyield({
                          data: {
                            question_id: question.QuestionId,
                            high_yield:
                              reviewHighYield?.HighYieldQuestions.includes(
                                question.QuestionId
                              )
                                ? false
                                : true,
                          },
                        }).unwrap();
                        const reviewObject = result.ReviewHighYield;
                        dispatch(setReviewHighYield({ ...reviewObject }));
                      }}
                    />
                    <span>High Yield</span>
                    <ToolTip
                      tip={
                        "High yield questions are marked to be more likely to appear in the real exam"
                      }
                      position="top"
                      width={undefined}
                    />
                  </div>
                  <div className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded-3px w flex space-x-5">
                    <CheckBox
                      text={undefined}
                      checked={reviewHighYield?.MarkedQuestions.includes(
                        question.QuestionId
                      )}
                      setChecked={async () => {
                        const result = await updateReviewLater({
                          data: {
                            question_id: question.QuestionId,
                            marked: reviewHighYield?.MarkedQuestions.includes(
                              question.QuestionId
                            )
                              ? false
                              : true,
                          },
                        }).unwrap();
                        const reviewObject = result.ReviewHighYield;
                        dispatch(setReviewHighYield({ ...reviewObject }));
                      }}
                    />
                    <span>Review Later</span>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Multiple choice questions */}
          {reviewedTest?.test.PrescriptionGrades.map((question, index) => {
            //const baseSize = textSizeIndexes[textSize];
            const lgSize =
              textSizeIndexes[
                Math.min(textSize + 2, textSizeIndexes.length - 1)
              ];

            let DrugFields = { Points: 0 };
            let StrengthFields = { Points: 0 };
            let DispFields = { Points: 0 };
            let RepeatFields = { Points: 0 };
            let SigFields = { Points: 0 };
            //let explanation = "";
            //let totalPoints = 0;
            let TotalPercentage = 0;

            if (question != undefined && question) {
              //explanation = question.Explanation;
              DrugFields = question.Drug;
              StrengthFields = question.Strength;
              DispFields = question.Disp;
              RepeatFields = question.Repeat;
              SigFields = question.Sig;
              //totalPoints = question.TotalPoints;

              TotalPercentage += DrugFields.Points * 0.2 * 100;
              TotalPercentage += StrengthFields.Points * 0.2 * 100;
              TotalPercentage += DispFields.Points * 0.2 * 100;
              TotalPercentage += SigFields.Points * 0.2 * 100;
              TotalPercentage += RepeatFields.Points * 0.2 * 100;
            }

            return (
              <div
                className="rounded-3px bg-white mx-10 mt-1 mb-2 p-5 shadow-custom"
                key={`${question.Question}${index}`}
              >
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-row">
                    <button
                      className={`${lgSize} font-semibold flex underline text-blue-900 mr-2`}
                      onClick={() => {
                        const questionId = question.QuestionId;
                        const choicesObject = {
                          [questionId]: [],
                        };
                        setReviewedQuestion({
                          reviewing: true,
                          questionId: questionId,
                          answers: choicesObject,
                          choices: choicesObject,
                          type: "Prescription",
                          reviewData: question,
                        });
                      }}
                    >
                      <span className="flex">
                        <Eye className="mr-2 mt-0.5" />
                        Perscription Question {index + 1}
                      </span>
                    </button>
                    <div className="mt-1 mr-2">
                      <ToolTip
                        tip={
                          "The 5 section points are averaged and multiplied by 4 for a total of a maximum of 4 points"
                        }
                        width={"w-[300px]"}
                      ></ToolTip>
                    </div>
                    <span className="font-semibold mt-0.5">
                      -{" "}
                      <span
                        className={
                          TotalPercentage < 70
                            ? "text-[#411414]"
                            : "text-[#175317]"
                        }
                      >
                        {TotalPercentage}%
                      </span>{" "}
                      - {question.Question}
                    </span>
                  </div>

                  <div className="rounded-3px bg-white p-5 space-y-2 shadow-inset-custom">
                    <PerscriptionAnswerBlock
                      examinationState={undefined}
                      setExaminationState={undefined}
                      textSize={undefined}
                      currentQuestionId={undefined}
                      reviewMode={true}
                      ShowAnswerMode={undefined}
                      reviewData={question}
                      ShowMyAnswerMode={undefined}
                    />
                  </div>
                </div>
                <div className="flex space-x-3 mt-5 justify-end">
                  <div className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded-3px w flex space-x-5">
                    <CheckBox
                      text={undefined}
                      checked={undefined}
                      setChecked={() => {
                        toast("Not connected yet");
                      }}
                    />
                    <span>High Yield</span>
                    <ToolTip
                      tip={
                        "High yield questions are marked to be more likely to appear in the real exam"
                      }
                      position="top"
                      width={undefined}
                    />
                  </div>
                  <div className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded-3px w flex space-x-5">
                    <CheckBox
                      text={undefined}
                      checked={undefined}
                      setChecked={() => {
                        toast("Not connected yet");
                      }}
                    />
                    <span>Review Later</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Modal
          isOpen={reviewedQuestion.reviewing}
          onClose={() => {
            setReviewedQuestion({
              reviewing: false,
              questionId: undefined,
              answers: undefined,
              choices: undefined,
              reviewData: undefined,
              type: undefined,
            });
          }}
          submit={false}
          fullWidth={true}
          closeHidden={true}
          xClose={true}
          title={""}
          onSubmit={undefined}
          pixelWidth={undefined}
          submitText={undefined}
          cancelText={undefined}
          submitDark={undefined}
          closeDark={undefined}
          buttonsSpaced={undefined}
        >
          {!isLoading &&
            !isFetching &&
            !questionImageFirstLoad &&
            !questionImageLoading &&
            data != undefined &&
            reviewedQuestion.questionId && (
              <div className="bg-white">
                <TestCenterColumns
                  centerColumSize={600}
                  currentQuestion={data}
                  textSize={{
                    left: "text-base",
                    right: "text-sm",
                  }}
                  getHighlightedText={getUnmodifiedHighlightedText}
                  currentQuestionImage={imageSource}
                  questionLoading={
                    isLoading ||
                    isFetching ||
                    questionImageFirstLoad ||
                    questionImageLoading
                  }
                  questionImageLoading={
                    isLoading ||
                    isFetching || questionImageFirstLoad ||
                    questionImageLoading
                  }
                  processQuestionText={processQuestionText}
                  GetShuffledAnswerArray={() => {
                    return GetUnmodifiedAnswerArray(data.GetAnswer);
                  }}
                  examinationState={{
                    currentQuestionIndex: 0,
                    answersById: { 0: [] },
                  }}
                  createdExam={{ QuestionsForTest: [] }}
                  handleAnswerChecked={() => {
                    console.log("Can't change answers in a review!");
                  }}
                  processQuestionLabel={processQuestionLabel}
                  completedAnswerArray={reviewedQuestion.answers}
                  showChecksAndXes={true}
                  questionType={questionType}
                  ShowMyAnswerMode={true}
                  reviewData={reviewedQuestion.reviewData}
                  setExaminationState={undefined}
                  setScrollChecks={undefined}
                  ScrollChecks={undefined}
                  imagePath={undefined}
                  showExplanations={undefined}
                  currentQuestionId={undefined}
                />
              </div>
            )}
          {(isFetching || questionImageLoading) && (
            <div
              className={`bg-primaryBackground flex justify-center items-center`}
            >
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-64 h-64 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default CompleteTests;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  tokenInvalidated: false,
  userData: {
    theme: "",
    time_zone: "",
    hide_warning: "",
    CompletedTests: [],
    IncompleteTests: [],
    ReviewHighYield: { HighYieldQuestions: [], MarkedQuestions: [] },
  },
  uiState: {
    sidebarHidden: false,
  },
  modelFetching: {
    test: { buffer: undefined, progress: 0, loading: false },
  },
};
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserData(state, action) {
      //Setting global variables for the user here after they login.
      state.userData = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.tokenInvalidated = action.payload.tokenInvalidated;
    },
    setUserDataTheme(state, action) {
      state.userData.theme = action.payload.theme;
    },
    setUserDataTimeZone(state, action) {
      state.userData.time_zone = action.payload.time_zone;
    },
    setUserDataWarnings(state, action) {
      state.userData.hide_warning = action.payload.hide_warning;
    },
    setUnauthenticated(state) {
      state.tokenInvalidated = true;
    },
    hideSidebar(state) {
      state.uiState.sidebarHidden = true;
    },
    showSidebar(state) {
      state.uiState.sidebarHidden = false;
    },
    setExamHistory(state, action) {
      state.userData.CompletedTests = action.payload.CompletedTests;
      state.userData.IncompleteTests = action.payload.IncompleteTests;
    },
    setLogout(state) {
      state.userData = {
        theme: "",
        time_zone: "",
        hide_warning: "",
        CompletedTests: [],
        IncompleteTests: [],
        ReviewHighYield: { HighYieldQuestions: [], MarkedQuestions: [] },
      };
      state.isAuthenticated = false;
      state.tokenInvalidated = true;
    },
    setModelProgress(state, action) {
      state.modelFetching[action.payload.key].progress =
        action.payload.progress;
      state.modelFetching[action.payload.key].loading = action.payload.loading;
    },
    setModelBuffer(state, action) {
      if (state.modelFetching[action.payload.key] == undefined) {
        state.modelFetching[action.payload.key] = {};
      }
      state.modelFetching[action.payload.key].buffer = action.payload.buffer;
      state.modelFetching[action.payload.key].progress =
        action.payload.progress;
      state.modelFetching[action.payload.key].loading = action.payload.loading;
    },
    setReviewHighYield(state, action) {
      state.userData.ReviewHighYield.HighYieldQuestions =
        action.payload.HighYieldQuestions;
      state.userData.ReviewHighYield.MarkedQuestions =
        action.payload.MarkedQuestions;
    },
  },
});

export const {
  setUserData,
  setUserDataTheme,
  setUserDataTimeZone,
  setUnauthenticated,
  hideSidebar,
  showSidebar,
  setExamHistory,
  setUserDataWarnings,
  setLogout,
  setModelProgress,
  setModelBuffer,
  setReviewHighYield,
} = loginSlice.actions;
export default loginSlice.reducer;
export const getUserData = (state) => state.user.userData;
export const getUserState = (state) => state.user;
export const getUserCategories = (state) => state.user.userData.category;
export const getUserAuthenticated = (state) => state.user.isAuthenticated;
export const getUserDataTheme = (state) => state.user.userData.theme;
export const getUserDataTimeZone = (state) => state.user.userData.time_zone;
export const getTokenInvalidated = (state) => state.user.tokenInvalidated;
export const getSidebarHidden = (state) => state.user.uiState.sidebarHidden;
export const getUserDataWarnings = (state) => state.user.userData.hide_warning;
export const getModelFetchingData = (state) => state.user.modelFetching;
export const getReviewHighYield = (state) =>
  state.user.userData.ReviewHighYield;

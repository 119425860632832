import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartAnnotation from "chartjs-plugin-annotation";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartAnnotation
);

const PerformanceOverTimeChart = ({ dailyPerformance }) => {
  const keysArray = Object.entries(dailyPerformance).map(([key]) => (key));
  const entriesArray = Object.entries(dailyPerformance);
  const performanceData = {
    labels: keysArray, // Dates
    datasets: [
      {
        label: "Performance",
        data: entriesArray, // Performance values corresponding to the dates
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Performance Over Time",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Performance",
        },
      },
    },
  };

  return (
    <div className="bg-white">
      <h2 className="text-center font-semibold text-xl mb-4">
        Performance Over Time
      </h2>
      <div style={{ position: "relative", margin: "auto", width: "60vw", height:"30vh" }}>
        {/*Overriding style here, to make chart fit,
          // @ts-expect-error. This works no need to add more params*/}
        <Line data={performanceData} options={options} />
      </div>
    </div>
  );
};

export default PerformanceOverTimeChart;

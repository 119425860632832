import { useRef } from "react";

function RadioButton({ text, count, checked, onChange }) {
  const idRef = useRef(`id-${Math.random().toString(36).substr(2, 9)}`);

  return (
    <div className="inline-flex items-center">
      <label
        className="relative flex items-center cursor-pointer"
        htmlFor={idRef.current}
      >
        <input
          type="radio"
          className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 checked:border-slate-400 transition-all"
          id={idRef.current}
          checked={checked}
          onClick={() => {
            onChange(!checked);
          }}
          onChange={() => {}}
        />
        <span className="absolute bg-blue-600 w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
      </label>
      <label
        className="ms-3 text-sm font-medium text-secondaryText"
        htmlFor={idRef.current}
      >
        {text}
      </label>
      {count !== undefined && (
        <label
          className="ms-3 text-sm font-medium text-secondaryText border border-gray-400 rounded-full p-0.5"
          htmlFor={idRef.current}
        >
          {count}
        </label>
      )}
    </div>
  );
}

export default RadioButton;

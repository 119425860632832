import { createBrowserRouter } from "react-router-dom";
import LayoutWrapper from "../components/Wrappers/layoutWrapper";
import LoginPage from "../pages/login";
import ProtectedRoute from "./protectedRoute";
import Dashboard from "../pages/dashboard";
import CreateTest from "../pages/createTest";
import Test from "../pages/test";
import ThemeWrapper from "../components/Wrappers/themeWrapper";
import Unauthwrapper from "../components/Wrappers/unauthwrapper";
import CompleteTests from "../pages/CompleteTests";
import IncompleteTests from "../pages/IncompleteTests";
import AccountSettings from "../pages/accountSettings";

const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/performance",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <LayoutWrapper>
              <Dashboard />
            </LayoutWrapper>
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
  {
    path: "/createTest",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <LayoutWrapper>
              <CreateTest />
            </LayoutWrapper>
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
  {
    path: "/accountsettings",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <LayoutWrapper>
              <AccountSettings />
            </LayoutWrapper>
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
  {
    path: "/incompleteTests",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <LayoutWrapper>
              <IncompleteTests />
            </LayoutWrapper>
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
  {
    path: "/completeTests",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <LayoutWrapper>
              <CompleteTests />
            </LayoutWrapper>
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
  {
    path: "/test",
    element: (
      <ProtectedRoute>
        <ThemeWrapper>
          <Unauthwrapper>
            <Test />
          </Unauthwrapper>
        </ThemeWrapper>
      </ProtectedRoute>
    ),
  },
];

/*
const debugMode = true//import.meta.env.VITE_DEBUG;

const debugRoutes = [
  {
    path: "/modelplayground",
    element: <ModelDisplayer imagePath={"question_10008_upper_jaw.ply"} />,
  },
  {
    path: "/charts",
    element: (
      <>
        <ThemeWrapper>
          <DummyMasterComponent />
        </ThemeWrapper>
      </>
    ),
  },
  {
    path: "/question/:questionId",
    element: (
      <ThemeWrapper>
        <Test />
      </ThemeWrapper>
    ),
  },
  {
    path: "/reviewed/:guid",
    element: (
      <ThemeWrapper>
        <CompleteTests />
      </ThemeWrapper>
    ),
  },
];

if (debugMode) routes.push(...debugRoutes);
*/
const router = createBrowserRouter(routes);

export default router;

import { useEffect, useMemo, useRef, useState } from "react";
import { Html, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { PLYLoader } from "three-stdlib";
import { ShaderMaterial } from "three";
import { Stats } from "@react-three/drei";
import { getAuthData } from "../../helpers/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  getModelFetchingData,
  setModelBuffer,
  setModelProgress,
} from "../../store/login.slice";
import { log } from "../../helpers/debug";

function ModelPLY({ modelArrayBuffer }) {
  log("Re render model");
  const [geometry, setGeometry] = useState(null);

  const material = useMemo(() => {
    return new ShaderMaterial({
      uniforms: {
        uColor: { value: 1.0 },
      },
      vertexShader: `
      varying vec3 vColor;
      void main() {
        vColor = color;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
      fragmentShader: `
      varying vec3 vColor;
      void main() {
        float gray = dot(vColor, vec3(0.3, 0.59, 0.11));
        gl_FragColor = vec4(vec3(gray), 1.0);
      }
    `,
      vertexColors: true,
    });
  }, []);

  useEffect(() => {
    if (modelArrayBuffer) {
      // Load geometry from ArrayBuffer
      const loader = new PLYLoader();
      const LoadedGeometry = loader.parse(modelArrayBuffer);
      setGeometry(LoadedGeometry);
    }
    return () => {
      if (geometry) {
        geometry.dispose();
      }
      if (material) {
        material.dispose();
      }
    };
  }, [modelArrayBuffer]);

  if (!geometry) return <></>;

  return (
    <group dispose={null}>
      <mesh castShadow receiveShadow geometry={geometry} material={material} />
    </group>
  );
}

const ModelDisplayer = ({ imagePath, questionLoading, currentQuestionId }) => {
  const dispatch = useDispatch();
  const modelFetchingData = useSelector(getModelFetchingData);
  const abortControllers = useRef(new Map());
  const previousPathRef = useRef(null);
  //log("Rendering model displayer", imagePath, modelFetchingData[imagePath]);
  //log("Current model download", abortControllers);

  const cleanupAllControllers = () => {
    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current.clear();
  };

  const updateProgress = (progress) => {
    dispatch(
      setModelProgress({
        key: imagePath,
        progress: progress,
        loading: progress != 0 && progress != 100,
      })
    );
  };

  const handleGrabModel = async (path) => {
    const controller = new AbortController();
    abortControllers.current.set(path, controller);

    const loginData = JSON.parse(getAuthData());
    const tokenCookie = loginData?.token;

    try {
      const arrayBuffer = await fetchWithProgress(
        `https://app.oscedental.com/3dimage.webapi?ImagePath=${path}`,
        updateProgress,
        {
          method: "GET",
          headers: {
            "Content-Type": "model/x-ply",
            TOKEN: tokenCookie,
            Image: path,
          },
        },
        controller
      );

      dispatch(
        setModelBuffer({
          key: path,
          buffer: arrayBuffer,
          progress: 100,
          loading: false,
        })
      );
    } catch (error) {
      if (!controller.signal.aborted) {
        log("Fetch failed:", error);
      } else {
        log("Aborted fetch for path:", path);
      }
    } finally {
      abortControllers.current.delete(path); // Clean up after fetch
    }
  };

  useEffect(() => {
    log("ID CHANGED!!", currentQuestionId);
    cleanupAllControllers();
  }, [currentQuestionId]);

  useEffect(() => {
    log("PATH CHANGED TO!!", imagePath);
    if (previousPathRef.current != imagePath) {
      log("Difference detected");
      previousPathRef.current = imagePath;
      if (
        modelFetchingData[imagePath] == undefined ||
        (modelFetchingData[imagePath]?.progress != 100 &&
          imagePath != undefined)
      ) {
        dispatch(
          setModelBuffer({
            key: imagePath,
            buffer: undefined,
            progress: 0,
            loading: false,
          })
        );
        handleGrabModel(imagePath);
        log("fetching fresh model for ", imagePath, currentQuestionId);
      } else {
        log("Fetching model from cache");
      }
    }
  }, [imagePath]);

  async function fetchWithProgress(url, updateProgress, options, controller) {
    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const contentLength = response.headers.get("Content-Length");

      if (!contentLength) {
        console.warn(
          "Unable to determine content length for progress tracking."
        );
        return await response.arrayBuffer();
      }

      const totalBytes = parseInt(contentLength, 10);
      let receivedBytes = 0;

      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedBytes += value.length;

        const currentProgress = Math.round((receivedBytes / totalBytes) * 100);
        updateProgress(currentProgress);
      }

      const arrayBuffer = new Uint8Array(receivedBytes);
      let position = 0;

      for (const chunk of chunks) {
        arrayBuffer.set(chunk, position);
        position += chunk.length;
      }

      return arrayBuffer.buffer;
    } catch (error) {
      log("Failed to fetch the model:", error);
      throw error;
    }
  }

  if (imagePath == undefined || questionLoading) {
    return <></>;
  }

  return (
    <div
      style={{ height: "600px", width: "600px", backgroundColor: "#f0f0f0" }}
    >
      <Canvas
        camera={{
          position: [0, 0, 100],
          fov: 75,
        }}
        style={{ height: "600px", width: "600px" }}
      >
        {!modelFetchingData[imagePath]?.buffer && (
          <Html position={[0, 0, 0]}>
            <div style={{ color: "white", fontSize: "20px" }}>
              Loading.. {modelFetchingData[imagePath]?.progress}%
            </div>
          </Html>
        )}
        {modelFetchingData[imagePath]?.buffer && (
          <ModelPLY modelArrayBuffer={modelFetchingData[imagePath].buffer} />
        )}
        <ambientLight intensity={1} />
        <mesh position={[0, 0, 0]} scale={[500, 500, 500]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color="gray" side={2} />
        </mesh>
        <OrbitControls />
        {false && <Stats />}
      </Canvas>
    </div>
  );
};

export default ModelDisplayer;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUnauthenticated } from "../store/login.slice";

const useTokenSpoilCheck = (statusCode) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (statusCode === 401) {
      dispatch(setUnauthenticated());
    }
  }, [statusCode, dispatch]);
};

export default useTokenSpoilCheck;

import { useDispatch, useSelector } from "react-redux";
import {
  getUserData,
  getUserDataTimeZone,
  setExamHistory,
} from "../store/login.slice";
import {
  useGettestsQuery,
  useStartIncompleteTestMutation,
} from "../api/testSlice.ts/testSlice";
import { useNavigate } from "react-router-dom";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";
import { useEffect, useState } from "react";
import { setCreatedTest, setTestStartSeconds } from "../store/test.slice";
import { formatDateTime } from "../helpers/formatters";

function IncompleteTests() {
  //
  //Selectors
  //
  const userData = useSelector(getUserData);
  const timeZone = useSelector(getUserDataTimeZone);

  //
  //local state
  //
  const [expanded, setExpanded] = useState([]);
  const [sortingMode, setSortingMode] = useState({
    start: "",
    last: "DESC",
  });
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);

  //
  //Hooks
  //
  useTokenSpoilCheck(errorStatusCode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //
  //Queries
  //
  const [startIncompleteTest] = useStartIncompleteTestMutation();

  const { data: tests, refetch } = useGettestsQuery({});

  //
  //UseEffect hooks
  //
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (tests?.CompletedTests && tests?.IncompleteTests) {
      const CompletedTests = tests.CompletedTests;
      const IncompleteTests = tests.IncompleteTests;
      dispatch(setExamHistory({ CompletedTests, IncompleteTests }));
    }
  }, [tests]);

  const Data = [...userData.IncompleteTests];

  const sortedData = Data.sort((a, b) => {
    // If sortingMode.start is not empty, sort by TimeTestStarted
    if (sortingMode.start) {
      const startA = new Date(a.TimeTestStarted);
      const startB = new Date(b.TimeTestStarted);
      if (sortingMode.start === "ASC") {
        // @ts-expect-error. Dates work with subtraction
        return startA - startB;
      } else if (sortingMode.start === "DESC") {
        // @ts-expect-error. Dates work with subtraction
        return startB - startA;
      }
    }

    // If sortingMode.last is not empty, sort by LastUsed
    if (sortingMode.last) {
      const lastA = new Date(a.LastUsed);
      const lastB = new Date(b.LastUsed);
      if (sortingMode.last === "ASC") {
        // @ts-expect-error. Dates work with subtraction
        return lastA - lastB;
      } else if (sortingMode.last === "DESC") {
        // @ts-expect-error. Dates work with subtraction
        return lastB - lastA;
      }
    }
    return 0; // Default case when no sorting mode is applied
  });

  return (
    <>
      <div className="bg-primaryBackground text-secondaryText font-normal p-4 flex justify-between w-full">
        <div>
          <span className="text-3xl">Incomplete Tests</span>
        </div>
      </div>
      <div className="rounded-lg bg-primaryBackground mx-5 mt-1 mb-2">
        <div className="flex space-x-5 p-4">
          <button
            className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
            onClick={() => {
              if (sortingMode.start == "") {
                setSortingMode({ last: "", start: "ASC" });
              } else if (sortingMode.start == "ASC") {
                setSortingMode({ last: "", start: "DESC" });
              } else if (sortingMode.start == "DESC") {
                setSortingMode({ last: "", start: "" });
              }
            }}
          >
            Sort By Start Date {sortingMode.start}
          </button>
          <button
            className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
            onClick={() => {
              if (sortingMode.last == "") {
                setSortingMode({ start: "", last: "ASC" });
              } else if (sortingMode.last == "ASC") {
                setSortingMode({ start: "", last: "DESC" });
              } else if (sortingMode.last == "DESC") {
                setSortingMode({ start: "", last: "" });
              }
            }}
          >
            Sort By Last Used {sortingMode.last}
          </button>
        </div>
      </div>
      {sortedData.map((test) => {
        const started = new Date(test.TimeTestStarted);
        const lastUsed = new Date(test.LastUsed);

        return (
          <div
            className="rounded-lg bg-primaryBackground mx-5 mt-1 mb-2"
            key={test.TimeTestStarted}
          >
            <div className="flex flex-row justify-between p-4">
              <div className="flex flex-col text-primaryText">
                <div className="flex flex-row">
                  <span>Test started:</span>
                  <span>{formatDateTime(timeZone.value, started)}</span>
                </div>
                <div className="flex flex-row">
                  <span>Last Used:</span>
                  <span>
                    {test?.LastUsed
                      ? formatDateTime(timeZone.value, lastUsed)
                      : "Unknown"}
                  </span>
                </div>
                <div className="flex flex-row">
                  <span>Test ID:</span>
                  <button
                    onClick={() => {
                      setExpanded((prev) =>
                        prev.includes(test.guid)
                          ? prev.filter((id) => id !== test.guid)
                          : [...prev, test.guid]
                      );
                    }}
                  >
                    {expanded.includes(test.guid)
                      ? test?.guid || "Unknown"
                      : test?.guid?.slice(0, 7) || "Unknown"}
                  </button>
                </div>
              </div>
              <div className="flex flex-col">
                <button
                  className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
                  onClick={async () => {
                    try {
                      const result = await startIncompleteTest({
                        testStartData: {
                          guid: test.guid,
                        },
                      }).unwrap();
                      dispatch(
                        setTestStartSeconds({
                          testStartSeconds: result.time_left,
                          testStartDate: Date.now(),
                        })
                      );
                      dispatch(
                        setCreatedTest({
                          createdTest: {
                            QuestionsForTest: result.questions_for_test,
                            guid: test.guid,
                            CapturedData: result.user_captured_data,
                            tutorMode: result.tutor_mode,
                          },
                        })
                      );
                      navigate("/test");
                    } catch (error) {
                      console.error(
                        "Error occurred during test creation:",
                        error
                      );
                      setErrorStatusCode(error?.status);
                      return;
                    }
                  }}
                >
                  <span>Continue Test </span>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default IncompleteTests;

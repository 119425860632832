function YourAnswersBlock({ question, showDisclaimer }) {
  return (
    <>
      {question?.YourAnswers && (
        <div className="rounded-3px bg-gray-100 px-5 py-3 text-[#4a4a4a] flex">
          <div className="flex flex-col pr-[100px]">
            <span className="font-bold text-lg">Your Answers</span>
            <div>
              {question?.YourAnswers.map((answer, index) => (
                <li key={index} className="">
                  {answer}
                </li>
              ))}
            </div>
          </div>
          {showDisclaimer && (
            <div className="w-[40%] text-center flex items-center text-[#411414]">
              <span className="font-bold">
                You answered some parts correctly, but you received a zero on
                the question because the real test scoring system will give a
                zero if any incorrect answers are selected
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default YourAnswersBlock;
